import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';

const getLogoPath = (company) => {

    const comp = company.toLowerCase();

    if (comp.startsWith('emsa'))
        return {
            path: 'emsa-logo.png',
            height: 38,
        };
    else if (comp.startsWith('impleo'))
        return {
            path: 'ImpleoLogo.png',
            height: 28,
        };

    return {
        path: '',
        height: 28,
    };
};

export const CompanyLogo = ({ company }) => {
    const [imageSrc, setImageSrc] = useState({ path: '', height: 0 });

    useEffect(() => {
        const logoInfo = getLogoPath(company);
        setImageSrc({
            ...imageSrc,
            path: logoInfo.path,
            height: logoInfo.height,
        });
    }, [company]);

    return imageSrc.path ? (
        <Fade in={true} timeout={1200}>
            <Box
                component="img"
                sx={{
                    height: imageSrc.height,
                    pl: '8px',
                }}
                src={imageSrc.path}
            />
        </Fade>
    ) : null;
};
