import React, { useState, useContext, useEffect, forwardRef } from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import MaterialTable from 'material-table'
import { serverContext, appContext, putToServer } from '../App';
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { useServerGet } from '../customEffects';
import SelectUsergroups from './SelectUsergroups'


const tableIcons = {
  AddIcon: forwardRef((props, ref) => <AddIcon {...props} ref={ref} />),
  DeleteIcon: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


const theme = createTheme({
  palette: {
    primary: {
      main: '#4caf50',
    },
    secondary: {
      main: '#47A3E8',
    },
  },
});



const PlatformInfoTable = props => {

  const { server } = useContext(serverContext);
  const { dispatchApp } = useContext(appContext);
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState([]);
  const [updated, setUpdated] = useState(Date.now());
  const { data: platforms} = useServerGet('platforms', server, updated);

  useEffect(() => {
    if (platforms) {
      const pl = platforms.map(p => {

        return {
          name: p.name,
          description: p.description,
          selectedUserGroups: p.usergroups ? p.usergroups : [],
          usergroups: p.usergroups ? p.usergroups.map(u => u.name).toString() : 'All'
        }
      });

      setData(pl);
    }
  }, [platforms])


  const columns = [
    { title: 'Name', field: 'name', tooltip: 'Platform name', editable: 'never', width: '20%' },
    { title: 'Description', field: 'description', tooltip: 'Platform description', editable: 'never', width: '25%' },
    {
      title: 'Usergroup', field: 'usergroups',
      editComponent: props => (
        <SelectUsergroups canDeleteDemo selectedUserGroups={props.rowData.selectedUserGroups} onUserGroupsChange={ug => handleUserGroupsChange(props.rowData.name, ug)} />
      )
    }
  ];



  const handleUserGroupsChange = async (name, ugs) => {

    console.log("handleUserGroupsChange")
    const dataUpdate = [...data];
    const pl = dataUpdate.find(d => d.name === name);
    pl.selectedUserGroups = ugs;
    setData([...dataUpdate]);
    if (server.serverHost) {

      try {
        const ids = ugs.map(g => g._id);

        const updated = await putToServer(`platforms/usergroups/${name}?ids=${ids.toString()}&createIfNotExists`, {}, server);
        setUpdated(Date.now());
      } catch (error) {

        dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error updating usergroups for ${name} : ${error.response.data}` } });
      }
    }
  }




  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        data={data}
        title={props.title || '✈︎ Platform Info'}
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow))}
        options={{
          selection: false,
          rowStyle: rowData => ({
            backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#EFFBFF' : '#FFF',
          }),
          headerStyle: {
            backgroundColor: '#EEE',
          }
        }}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              // const dataUpdate = [...data];
              // const index = oldData.tableData.id;
              // dataUpdate[index] = newData;
              // setData([...dataUpdate]);
              // setUpdated(new Date.now());
              resolve();
            }),
        }}
      />
    </MuiThemeProvider>
  );
}



export default PlatformInfoTable; 
