import React, { useContext, useState, useEffect } from 'react';
import { serverContext } from './App';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import { useFetchImageFromVideoServer } from './customEffects';
import ImageIcon from '@material-ui/icons/Image';

const useStyles = makeStyles(theme => ({
  mediaContainer: {
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  media: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: '100%',
    maxWidth: '100%',
  },
  mediaRootThumbnail: {
    width: '100%',
  },
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#f0f0f0',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
}));

function DocumentPreview(props) {
  const { server } = useContext(serverContext);
  const { document } = props;
  const { isLoading, hasError, image } = useFetchImageFromVideoServer(
    document.thumbnail && document.thumbnail ? `${server.serverHost}/documents/${document.thumbnail}` : null,
    server.token,
    document.thumbnail
  );

  const [aspectRatio, setAspectRatio] = useState(null);

  useEffect(() => {
    if (image) {
      const img = new Image();
      img.onload = () => {
        setAspectRatio(img.width / img.height);
      };
      img.src = image;
    } else {
      setAspectRatio(null);
    }
  }, [image]);

  const classes = useStyles();

  const mediaContainerStyle = aspectRatio
    ? { paddingBottom: `${(1 / aspectRatio) * 100}%` }
    : { paddingBottom: '75%' }; // Default to 4:3 if no image or aspect ratio

  return (
    <Box style={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "center", justifyContent: "center" }}>
      <Card className={classes.mediaRootThumbnail}>
        <CardActionArea>
          {document.documentType === 'Image' ? (
            <div className={classes.mediaContainer} style={mediaContainerStyle}>
              {isLoading && (
                <div className={classes.placeholder}>
                  <CircularProgress size={40} />
                </div>
              )}
              {!isLoading && hasError && (
                <div className={classes.placeholder}>
                  <Typography color="error">Error loading image</Typography>
                </div>
              )}
              {!isLoading && !hasError && !image && (
                <div className={classes.placeholder}>
                  <ImageIcon color="disabled" fontSize="large" />
                </div>
              )}
              {!isLoading && !hasError && image && (
                <CardMedia
                  className={classes.media}
                  component="img"
                  image={image}
                  alt={document.fileName}
                />
              )}
            </div>
          ) : (
            <div className={classes.placeholder}>
              <ImageIcon color="disabled" fontSize="large" />
            </div>
          )}
        </CardActionArea>
      </Card>

      <Typography variant="subtitle1" color="text.secondary" gutterBottom>
        File: {document.fileName}
      </Typography>
    </Box>
  );
}

DocumentPreview.propTypes = {
  document: PropTypes.shape({
    thumbnail: PropTypes.string,
    fileName: PropTypes.string.isRequired,
    documentType: PropTypes.string.isRequired,
  }).isRequired,
};

export default DocumentPreview;
