import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import MovieFilterIcon from '@material-ui/icons/MovieFilter';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive';
import DescriptionIcon from '@material-ui/icons/Description';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import { Link as RouterLink } from 'react-router-dom';
import List from '@material-ui/core/List';
import Tooltip from '@material-ui/core/Tooltip';

export const MainListItems = ({ userRoleAdmin }) => {
  return (
    <List>
      <ListItem button component={RouterLink} to="/missions">
        <ListItemIcon>
          <Tooltip title="VOD">
            <MovieFilterIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="VOD" />
      </ListItem>
      <ListItem button component={RouterLink} to="/live">
        <ListItemIcon>
          <Tooltip title="Live">
            <LiveTvIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Live" />
      </ListItem>
      <ListItem button component={RouterLink} to="/documents">
        <ListItemIcon>
          <Tooltip title="Documents">
            <DescriptionIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Docs" />
      </ListItem>
      {userRoleAdmin &&
        <div>
          <Divider />
          <ListItem button component={RouterLink} to="/platforms">
            <ListItemIcon>
              <Tooltip title="Platforms">
                <AirplanemodeActiveIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Platforms" />
          </ListItem>
          <ListItem button component={RouterLink} to="/admin">
            <ListItemIcon>
              <Tooltip title="Admin">
                <SettingsIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Admin" />
          </ListItem>
          <ListItem button component={RouterLink} to="/stsupervisor">
            <ListItemIcon>
              <Tooltip title="Monitor">
                <ShowChartIcon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Monitor" />
          </ListItem>
        </div>
      }
      {/* <ListItem button component={RouterLink} to="/settings">
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItem> */}
    </List>
  )
}

export const SecondaryListItems = () => {
  return (
    <List>
      <ListSubheader inset>Misc</ListSubheader>
      <ListItem button component={RouterLink} to="/help">
        <ListItemIcon>
          <Tooltip title="Help">
            <HelpIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Help" />
      </ListItem>
      <ListItem button component={RouterLink} to="/about">
        <ListItemIcon>
          <Tooltip title="About">
            <InfoIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="About" />
      </ListItem>
    </List>
  )
}
