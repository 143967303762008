import React, { useEffect, useState, useContext } from 'react';
import { Box, Typography, TextField, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { serverContext, fetchFromServer } from '../App';

import ReactJson from 'react-json-view';

const styles = {
    jsonView: {
        fontSize: '12px',
        lineHeight: '90%',
    },
};

function decodeBase64AndParseJSON(encodedString) {
    const parts = encodedString.split('.');
    if (parts.length !== 3) {
        throw new Error('Provided string is not JWT.');
    }

    const encodedJSON = parts[1];

    // Check if the middle part is base64 encoded
    const isBase64Encoded = /^[a-zA-Z0-9-_]+={0,2}$/.test(encodedJSON);
    if (!isBase64Encoded) {
        throw new Error('The payload section of the license is not base64 encoded.');
    }

    try {
        // Decode the base64 encoded JSON to string
        const decodedString = atob(encodedJSON);

        // Parse the decoded JSON string to JavaScript object
        const parsedJSON = JSON.parse(decodedString);

        return parsedJSON;
    } catch (error) {
        console.error('Error decoding or parsing JSON:', error);
        return null;
    }
}




function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}



export default function ShowLicenseDlg(props) {

    const { onCancel, onAlert } = props;
    const { server } = useContext(serverContext);
    const [tabValue, setTabValue] = React.useState(0);
    const [decodedLicense, setDecodedLicense] = useState();
    const [licenseFile, setLicenseFile] = useState('');
    const [token, setToken] = useState('');

    useEffect(() => {

        const loadLicense = async () => {
            try {
                const res = await fetchFromServer('licenseFile', server);
                if (res.data) {
                    const { file, token } = res.data;
                    setLicenseFile(file);
                    setToken(token);

                    if (token) {
                        try {
                            const lic = decodeBase64AndParseJSON(token);
                            setDecodedLicense(lic);
                            onAlert?.('Token decoded.', 'success');
                        } catch (error) {
                            onAlert?.(`Cannot decode token. ${error.message}`, 'error');
                        }
                    }
                }

            } catch (error) {
                setLicenseFile('')
                setToken('');
            }
        }

        loadLicense();

    }, [])


    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleTokenChange = (event) => {
        if (event.target.value) {
            setToken(event.target.value);
        }
    };


    return (
        <Dialog open={true} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',

                }}
            >
                <Box sx={{ padding: 2, width: '90%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', maxHeight: '400px', overflow: 'auto' }}>
                        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="License" />
                            <Tab label="Token" />
                        </Tabs>
                    </Box>
                    <div style={{ padding: '20px' }}>
                        <TabPanel value={tabValue} index={0}  >
                            <Typography variant="caption" display="block" gutterBottom >
                                License file: {licenseFile}
                            </Typography>
                            <ReactJson
                                displayObjectSize={false}
                                displayDataTypes={false}
                                name={false}
                                theme={'rjv-default'}
                                style={styles.jsonView}
                                enableClipboard={false}
                                quotesOnKeys={false}
                                src={decodedLicense || {}}
                            />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <Box sx={{
                                width: '90%',
                                marginLeft: '20px',
                                marginRight: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                             }}>
                                <TextField id="outlined-controlled" label="Token" value={token} disabled multiline size="small" onChange={handleTokenChange}
                                    sx={{
                                        width: '100%',
                                        marginLeft: '10px',
                                        marginRight: '10px'
                                    }}
                                />
                            </Box>
                        </TabPanel>
                    </div>
                    <Box justifyContent="flex-end" spacing={4} sx={{ mt: 2, mb: 2 }}>
                        <Button size="small" variant="contained" color="primary" onClick={() => onCancel?.()}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
};