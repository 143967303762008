
export function captureCurrentFrame(player, scaleFactor) {
    if (scaleFactor == null) {
        scaleFactor = 1;
    }

    const w = player.videoWidth() * scaleFactor;
    const h = player.videoHeight() * scaleFactor;
    const canvas = document.createElement('canvas');
    canvas.width = w;
    canvas.height = h;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(player.children_[0], 0, 0, w, h);
    return canvas;
  }
  
export function checkUserAuthorization(server, document) {

    if (!document.restricted)
        return true;

    const { userRole, currentUserGroups } = server;
    if (userRole !== 'superAdmin' && userRole !== 'admin') {
        //  if (document.author !== user.username) {
        if (userRole !== 'groupAdmin') {
            return false;
        } else {
            const allowedGroups = currentUserGroups.usergroupsAdmin.map(g => g._id)
            const isGroupAdmin = document.usergroups.some(group => allowedGroups.includes(group));
            return isGroupAdmin;
        }
        //  }
    } 
    else 
        return true;
}

export function checkUserAuthorizationForRestricted(server, document) {

    const { userRole, currentUserGroups } = server;
    if (userRole !== 'superAdmin' && userRole !== 'admin') {
        //  if (document.author !== user.username) {
        if (userRole !== 'groupAdmin') {
            return false;
        } else {
            const allowedGroups = currentUserGroups.usergroupsAdmin.map(g => g._id)
            const isGroupAdmin = document.usergroups.some(group => allowedGroups.includes(group));
            return isGroupAdmin;
        }
        //  }
    } 
    else 
        return true;
}