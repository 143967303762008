import React, { useState, useContext, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Rating } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import { serverContext, fetchFromServer, missionsContext } from '../App';
import { newMissionContext } from '../CreateMissionDlg';
import { useGetUsergroups } from '../customEffects';
import TagsArray from './TagsArray';
import CountrySelect from './CountrySelect';
//import PlatformTypeSelect from './PlatformTypeSelect';
import SelectUsergroups from './SelectUsergroups'
import { securityType } from '../commonTypes';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 240,
    }
  },
  select: {
    minwidth: 240,
    maxWidth: 600,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
    maxWidth: 600,
  },
  typographyLabel: {
    color: "grey",
  }

}));



const hasWhiteSpace = s => {
  return /\s/g.test(s);
}

/**
 * @param  {} name - new Mission name to validate
 * @param  {} server
 * 
 * Rejects with error if a mission with the same name already exists
 */
const validateMissionName = (name, server) => {

  return new Promise(async (resolve, reject) => {

    try {

      if (!name || name.length === 0)
        return reject(new Error(`Empty Mission name`));

      if (hasWhiteSpace(name))
        return reject(new Error(`Spaces are not allowed`));

      await fetchFromServer(`missions/${name}`, server);
      reject(new Error(`Mission name ${name} already exists`));

    } catch (error) {

      resolve();
    }
  })
}

const getSelectedUserGroups = (allUserGroups, missionUserGroups) => {
  if (allUserGroups == null || missionUserGroups.length === 0)
    return [];

  return allUserGroups.filter(g => (missionUserGroups && missionUserGroups.includes(g._id)));
}



/**
 * @param  {} props
 */
export default function MissionGeneralForm(props) {

  const { server } = useContext(serverContext);
  const { missions } = useContext(missionsContext);
  const { newMission, dispatchNewMission } = useContext(newMissionContext);
  const [selectedUserGroups, setSelectedUserGroup] = useState([]);
  const [tags] = useState(Array.isArray(missions) ? [...missions.filter(m => m.tags).map(m => m.tags)].flat().filter((x, i, a) => a.indexOf(x) === i) : []);
  const [selectedTags, setSelectedTags] = useState(props.editMode ? newMission.tags : []);
  const [nameError, setNameError] = useState({ error: false, errorText: '' });

  const classes = useStyles();

  useGetUsergroups();

  useEffect(() => {

    if (props.editMode) {

      if (server.usergroups && server.usergroups.length > 0 && newMission.usergroups.length > 0) {
        const grToSel = getSelectedUserGroups(server.usergroups, newMission.usergroups);
        setSelectedUserGroup(grToSel);
      }

      setSelectedTags(newMission.tags);
    }
  }, [newMission, newMission.usergroups, server.usergroups, props.editMode])


  useEffect(() => {

    handleNameChange(newMission.name);
  }, [newMission.name, props.editMode])


  const handleNameChange = async name => {

    if (props.editMode || newMission.name === name)
      return;

    try {
      dispatchNewMission({ type: 'SET_NAME', payload: name });
      await validateMissionName(name, server);
      setNameError({ error: false, errorText: '' });
    } catch (error) {
      setNameError({ error: true, errorText: error.message });
    }
  }

  const handleDescriptionChange = e => {
    dispatchNewMission({ type: 'SET_DESCRIPTION', payload: e.target.value });
  }

  const handleUserGroupsChange = ugs => {
    setSelectedUserGroup(ugs);
    const usergroupsIds = ugs.map(g => g._id)
    dispatchNewMission({ type: 'SET_USERGROUPS', payload: usergroupsIds });
  }

  const handleSecurityChange = e => {
    dispatchNewMission({ type: 'SET_SEC_CLASSIFICATION', payload: e.target.value });
  }

  const handleRatingChange = (event, newValue) => {
    dispatchNewMission({ type: 'SET_RATING', payload: newValue });
  }

  const handleTagsChange = (event, newValue) => {
    dispatchNewMission({ type: 'SET_TAGS', payload: newValue });
  }

  const handleCountryChange = newValue => {
    if (newValue !== null)
      dispatchNewMission({ type: 'SET_COUNTRY', payload: { country_name: newValue.name, country_code: newValue.country } });
    else
      dispatchNewMission({ type: 'SET_COUNTRY', payload: { country_name: '', country_code: '' } });
  }

  const handlePlatformTypeChange = newPlatformType => {
    if (newPlatformType !== null)
      dispatchNewMission({ type: 'SET_PLATFORM_TYPE', payload: newPlatformType });
    else
      dispatchNewMission({ type: 'SET_PLATFORM_TYPE', payload: 'Plane' });
  }



  return (
    <div>
      <form className={classes.root} noValidate autoComplete="off">
        <div>
          <TextField required id="mission-name" label="Name"
            value={newMission.name}
            onChange={e => { handleNameChange(e.target.value) }}
            error={nameError.error}
            helperText={nameError.errorText}
            disabled={props.editMode}
          />
          <TextField id="text-description" label="Description" defaultValue={newMission.description} onChange={handleDescriptionChange} />
          <FormControl className={classes.formControl}>
            <InputLabel>User group</InputLabel>
            <SelectUsergroups canDeleteDemo selectedUserGroups={selectedUserGroups} onUserGroupsChange={handleUserGroupsChange} />
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel>Security classification</InputLabel>
            <Select
              labelId="classification-select-label"
              id="classification-select"
              value={newMission.securityClassification}
              onChange={handleSecurityChange}
            >
              {securityType.map(sec => (
                <MenuItem key={sec.value} value={sec.value}>{sec.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* {props.liveMissionMode &&
            <FormControl >
              <PlatformTypeSelect platformType={newMission.platform?.type} onChange={handlePlatformTypeChange} />
            </FormControl>
          } */}
          <FormControl >
            <CountrySelect country_code={newMission.country_code} country_name={newMission.country_name} onChange={handleCountryChange} />
          </FormControl>
          {!props.liveMissionMode &&
            <FormControl className={classes.formControl}>
              <Typography className={classes.typographyLabel} variant="caption" gutterBottom>
                Rating
              </Typography>
              <Rating value={newMission.rating} name="Rating" size="small" onChange={handleRatingChange} />
            </FormControl>
          }
        </div>
      </form>
      <TagsArray tags={tags} selectedTags={selectedTags} onChange={handleTagsChange} />
    </div>
  );
}
