import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Marker, Popup, Polyline, Tooltip, FeatureGroup } from 'react-leaflet';
import RotatedMarker from './RotatedMarker';
import AntPath from "react-leaflet-ant-path";
import _planeGreyIcon from "./img/planeGrey.png";
import _planeBlueIcon from "./img/planeBlue.png";
//import _planeShadowIcon from "./img/plane_shadow.png";
import _helicopterBlueIcon from "./img/helicopterBlue.png";
import _helicopterGreyIcon from "./img/helicopterGrey.png";
//import _helicopterShadowIcon from "./img/helicopter_shadow.png";
import _quadcopterIcon from "./img/quadcopter.png";
//import _quadcopterShadowIcon from "./img/quadcopter_shadow.png";
import _rovIcon from "./img/rov.png";
//import _rovShadowIcon from "./img/rov_shadow.png";
import _cameraIcon from "./img/camera.png";
//import _cameraShadowIcon from "./img/camera_shadow.png";
import _diverIcon from "./img/diver.png";
//import _diverShadowIcon from "./img/diver_shadow.png";
import _crossIcon from "./img/cross.png";
import _crosshairIcon from "./img/crosshair.png";
import _heronBlueIcon from "./img/heronBlue.png";
import _heronGreyIcon from "./img/heronGrey.png";
//import _heronShadowIcon from "./img/heron_shadow.png";

const L = window.L;

const frameCenterIcon = L.icon({
    iconUrl: _crossIcon,
    iconSize: [20, 20], // size of the icon
    iconAnchor: [10, 10], // point of the icon which will correspond to marker's location
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
});

const crosshairIcon = L.icon({
    iconUrl: _crosshairIcon,
    iconSize: [28, 28], // size of the icon
    iconAnchor: [14, 14], // point of the icon which will correspond to marker's location
    popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
});

// Helpers

function getFootprintLine(data) {

    let lat, lon;
    let coords = [lon, lat];
    let lineArray = [];

    if (data.hasOwnProperty('82') && data.hasOwnProperty('83') && data.hasOwnProperty('84') && data.hasOwnProperty('85') && data.hasOwnProperty('86') && data.hasOwnProperty('87') && data.hasOwnProperty('88') && data.hasOwnProperty('89')) {

        coords = [data['82'], data['83']];
        lineArray.push(coords);

        coords = [data['84'], data['85']];
        lineArray.push(coords);

        coords = [data['86'], data['87']];
        lineArray.push(coords);

        coords = [data['88'], data['89']];
        lineArray.push(coords);

        coords = [data['82'], data['83']];
        lineArray.push(coords);
    } else
        if (data.hasOwnProperty('23') && data.hasOwnProperty('24') && data.hasOwnProperty('26') && data.hasOwnProperty('27') && data.hasOwnProperty('28') && data.hasOwnProperty('29') && data.hasOwnProperty('30') && data.hasOwnProperty('31') && data.hasOwnProperty('32') && data.hasOwnProperty('33')) {

            let frameCenterLat = data['23'];
            let frameCenterLon = data['24'];

            lat = frameCenterLat + data['26'];
            lon = frameCenterLon + data['27'];
            coords = [lat, lon];
            lineArray.push(coords);

            lat = frameCenterLat + data['28'];
            lon = frameCenterLon + data['29'];
            coords = [lat, lon];
            lineArray.push(coords);

            lat = frameCenterLat + data['30'];
            lon = frameCenterLon + data['31'];
            coords = [lat, lon];
            lineArray.push(coords);

            lat = frameCenterLat + data['32'];
            lon = frameCenterLon + data['33'];
            coords = [lat, lon];
            lineArray.push(coords);

            lat = frameCenterLat + data['26'];
            lon = frameCenterLon + data['27'];
            coords = [lat, lon];
            lineArray.push(coords); //footprint
        }
        else
            return null;

    return lineArray;
}

function getVmtiBBoxLine(target, frameCenter) {

    let lat, lon;
    let coords = [lon, lat];
    let lineArray = [];

    const vTargetPack = target['vTargetPack'];

    if (vTargetPack.hasOwnProperty('13') && vTargetPack.hasOwnProperty('14') && vTargetPack.hasOwnProperty('15') && vTargetPack.hasOwnProperty('16')) {

            let frameCenterLat = frameCenter[0];
            let frameCenterLon = frameCenter[1];

            lat = frameCenterLat + vTargetPack['13'];
            lon = frameCenterLon + vTargetPack['14'];
            coords = [lat, lon];
            lineArray.push(coords);

            lat = frameCenterLat + vTargetPack['13'];
            lon = frameCenterLon + vTargetPack['16'];
            coords = [lat, lon];
            lineArray.push(coords);
          
            lat = frameCenterLat + vTargetPack['15'];
            lon = frameCenterLon + vTargetPack['16'];
            coords = [lat, lon];
            lineArray.push(coords);

            lat = frameCenterLat + vTargetPack['15'];
            lon = frameCenterLon + vTargetPack['14'];
            coords = [lat, lon];
            lineArray.push(coords);

            lat = frameCenterLat + vTargetPack['13'];
            lon = frameCenterLon + vTargetPack['14'];
            coords = [lat, lon];
            lineArray.push(coords);
        }
        else
            return null;

    return lineArray;
}

function getVmtiCenterLine(target) {

    let lat, lon;
    let coords = [lon, lat];
    let lineArray = [];

    const vTargetPack = target['vTargetPack'];

    if (vTargetPack.hasOwnProperty('17')) {

            lat = vTargetPack['17'][0] + 0.0001;
            lon = vTargetPack['17'][1];
            coords = [lat, lon];
            lineArray.push(coords);

            lat = vTargetPack['17'][0] - 0.0001;
            lon = vTargetPack['17'][1];
            coords = [lat, lon];
            lineArray.push(coords);

            lat = vTargetPack['17'][0];
            lon = vTargetPack['17'][1];
            coords = [lat, lon];
            lineArray.push(coords);

            lat = vTargetPack['17'][0]; 
            lon = vTargetPack['17'][1] + 0.0001;
            coords = [lat, lon];
            lineArray.push(coords);
            
            lat = vTargetPack['17'][0]; 
            lon = vTargetPack['17'][1] - 0.0001;
            coords = [lat, lon];
            lineArray.push(coords);
        }
        else
            return null;

    return lineArray;
}

function isTargetExist(data) {
    return (data.hasOwnProperty('40') && data.hasOwnProperty('41'));
}

function getPlatformToTargetLine(data) {
    return [[data['13'], data['14']], [data['40'], data['41']]];
}

function getPlatformToFrameCenterLine(data) {
    return [[data['13'], data['14']], [data['23'], data['24']]];
}


function createIcon(type, isActivePlatform) {

    let icon;
  //  let shadowIcon;
    switch (type) {
        case "plane":
            icon = isActivePlatform ? _planeBlueIcon : _planeGreyIcon;
    //        shadowIcon = _planeShadowIcon;
            break;
        case "heron":
            icon = isActivePlatform ? _heronBlueIcon : _heronGreyIcon;
     //       shadowIcon = _heronShadowIcon;
            break;
        case "helicopter":
            icon = isActivePlatform ? _helicopterBlueIcon : _helicopterGreyIcon;
      //      shadowIcon = _helicopterShadowIcon;
            break;
        case "quadcopter":
            icon = _quadcopterIcon;
     //       shadowIcon = _quadcopterShadowIcon;
            break;
        case "rov":
            icon = _rovIcon;
     //       shadowIcon = _rovShadowIcon;
            break;
        case "camera":
            icon = _cameraIcon;
    //        shadowIcon = _cameraShadowIcon;
            break;
        case "diver":
            icon = _diverIcon;
     //       shadowIcon = _diverShadowIcon;
            break;
        default:
            icon = isActivePlatform ? _planeBlueIcon : _planeGreyIcon;
     //       shadowIcon = _planeShadowIcon;
            break;
    }
 
    return L.icon({
        iconUrl: icon,
    //    shadowUrl: shadowIcon,
        iconSize: [48, 48], // size of the icon
        // shadowSize: [40, 40], // size of the shadow
        iconAnchor: [24, 24], // point of the icon which will correspond to marker's location
        // shadowAnchor: [2, 38], // the same for the shadow
        popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor           
    });
}

// Primitives

const RotatedPlatform = ({ data, platformType, isActivePlatform, onInfoClick }) => {
    if (data.hasOwnProperty('13') && data.hasOwnProperty('14')) {

        return (
            <RotatedMarker rotationAngle={data['5'] || 0.0} position={[data['13'], data['14']]} icon={createIcon(platformType, isActivePlatform)}>
                <Popup>
                    <div>
                        <p>Platform: <b>{data['10']}</b><br />
                       Tail:     <b>{data['4']}</b><br />
                       Mission:  <b>{data['3']}</b>
                        </p>
                        <button onClick={onInfoClick}>Info</button>
                    </div>
                </Popup>
            </RotatedMarker>
        )
    }
    else
        return null;
}

const TargetMarker = ({ data }) => {
    if (isTargetExist(data)) {
        return <Marker position={[data['40'], data['41']]} icon={crosshairIcon}>
            <Popup>
                <span>`Lat: ${data['40'].toFixed(6)}, Lon: ${data['41'].toFixed(6)}`</span>
            </Popup>
        </Marker>
    }
    else
        return null;
}

const FramecenterIcon = ({ data }) => {

    if (data.hasOwnProperty('23') && data.hasOwnProperty('24')) {
        const message = 'Lat: ' + data['23'].toFixed(6) + ', Lon: ' + data['24'].toFixed(6);
        return <Marker position={[data['23'], data['24']]} icon={frameCenterIcon}>
                    <Popup>         
                        <span>{message}</span>            
                    </Popup>
                </Marker>
    }
    else
        return null;
}

const Footprint = ({ data }) => {

    const footPrint = getFootprintLine(data);
    if (footPrint != null)
        return <Polyline color="blue" opacity="0.8" dashArray="5,2" weight="1" positions={getFootprintLine(data)} />
    else
        return null;
}

/**
 * Draw VMTI
 * @param  {} {data} klv packet
 */
const Vmti = ({ data, show }) => {

    if(!show)
        return null;

    const vmti = data['74'];
    if(vmti == null)
        return null;

    const targets = vmti['101'];
    if(targets == null)
        return null;
    
    const vmtiVersion = vmti['4'];
    const frameCenter = [data['23'], data['24']];
      
    // Draw bounding box
    const bboxItems = targets.map(target =>  
        getVmtiBBoxPolyline(target, frameCenter)
    );

    // Draw target center
    const centerItems = targets.map(target => 
        getVmtiCenter(target)
    );

    const trackItems = targets.map(target => 
        getTrackPath(target, vmtiVersion)
    );

    return <div>
                {bboxItems}
                {centerItems}
                {trackItems}
           </div>
}

const Trail = ({show, trail, delay}) => {

    return  show && <AntPath positions={trail} options={{ color: 'blue', weight: 3, delay: delay }} />
}


const getVmtiBBoxPolyline = (target, frameCenter) => {
    const pos = getVmtiBBoxLine(target, frameCenter);
    const color = target['vTargetPack']['8'] || 'blue';
    return pos != null ? <Polyline key={target['id']} color={color} opacity="0.8" weight="1" positions={pos}/> : null; 
}

const getVmtiCenter = (target) => {
    const pos = getVmtiCenterLine(target);
    const color = target['vTargetPack']['8'] || 'blue';
    const idTooltip = 'id: ' + target.id;
    return pos != null ? <Polyline key={target['id']} color={color} opacity="0.8" weight="2" positions={pos} >
                            <Tooltip><span>{idTooltip}</span></Tooltip>
                         </Polyline> : null; 
}

const getTrackPath = (target, version) => {

    let trackIdByVersion = '105';

    if(version === undefined || version !== 3)
        trackIdByVersion = '104';

    if (target['vTargetPack'].hasOwnProperty(trackIdByVersion) && target['vTargetPack'][trackIdByVersion].hasOwnProperty('9')) {

        const track = target['vTargetPack'][trackIdByVersion]['9'];
        let trackPath = track.map(t => [t[0], t[1]]);
        const color = target['vTargetPack']['8'] || 'blue';

        return <AntPath key={target['id']} positions={trackPath} options={{ color: color, weight: 1, delay: 1000, dasharray: "5, 5" }}/>;
    } else 
        return null;
}

const PlatformToFrameCenterLine = ({ data }) => {
    if (data.hasOwnProperty('13') && data.hasOwnProperty('14') && data.hasOwnProperty('23') && data.hasOwnProperty('24') && 
        (parseFloat(data['23'].toFixed(3)) !== -90.000 && parseFloat(data['24'].toFixed(3)) !== -180.000)) {
        return <Polyline color="blue" opacity="0.8" dashArray="5,5" weight="1" positions={getPlatformToFrameCenterLine(data)} />
    }
    else
        return null;
}

const PlatformToTargetLine = ({ data }) => {
    if (data.hasOwnProperty('13') && data.hasOwnProperty('14') && data.hasOwnProperty('40') && data.hasOwnProperty('41') &&
    (parseFloat(data['40'].toFixed(3)) !== -90.000 && parseFloat(data['41'].toFixed(3)) !== -180.000)) {
        return <Polyline color="red" opacity="0.4" dashArray="15,10,5,10" weight="1" positions={getPlatformToTargetLine(data)} />
    }
    else
        return null;
}


class Platform extends Component {
    
    static propTypes = {
        data: PropTypes.object,
        isActivePlatform: PropTypes.bool,
        platformType: PropTypes.string,
        showVmti: PropTypes.bool,
        showTrail: PropTypes.bool,
        trailDuration: PropTypes.number,
        trailDelay: PropTypes.number,
        clearTrailThreshold: PropTypes.number
    }

    static defaultProps = {  
        isActivePlatform: true,
        platformType: 'plane',
        showVmti: true,
        showTrail: true,
        trailDuration: 20,                  // keep track duration in sec
        trailDelay: 3000,                   // Movement speed
        clearTrailThreshold: 2000000        // clear the track if time difference is greater than this.  
    }

    constructor(props) {
        super(props)

        this.state = {
            name: this.props.name,                                       // Platform name
            track: [[this.props.data['13'], this.props.data['14']]],     // LatLon 
            trackTime: []                                                // track point time              
        }

        this.updateTrack = this.updateTrack.bind(this);
        this.clearTrack = this.clearTrack.bind(this);
        this.handleInfoClick = this.handleInfoClick.bind(this);
    }

    // componentDidMount() {
    //     this.platformRef = React.createRef();
    // }   

    componentWillReceiveProps(nextProps) {
        // check if there is a big difference in timestamp. If yes, there is most likely a discontinuity, so empty the array.
        // const lastTime = this.state.track[this.state.track.length - 1];
        // console.log("delta " + Math.abs(nextProps.data['2'] - this.props.data['2']) );
        if (Math.abs(nextProps.data['2'] - this.props.data['2']) > this.props.clearTrackThreshold) {
            //console.log("clearTrackThreshold ")
            this.clearTrack();
        }
    }

    componentDidUpdate(prevProps, prevState) {    
        this.updateTrack();
    }

    updateTrack() {
     
        if(this.props.name !== this.state.name) {
            this.clearTrack();
            this.setState({name : this.props.name});     
        }          

        if (this.props.data.hasOwnProperty('13') && this.props.data.hasOwnProperty('14')) {
            let trackClone = [...this.state.track];
            let trackTime = this.state.trackTime;
            trackClone.push([this.props.data['13'], this.props.data['14']]);
            trackTime.push(this.props.data['2']);
            // console.log(Math.abs(trackTime[trackTime.length - 1] - trackTime[0] ) / 1000000);
            if (Math.abs(trackTime[trackTime.length - 1] - trackTime[0] ) / 1000000 > this.props.trailDuration){
                trackTime.shift();
                trackClone.shift();
            }
               
            // Set this way on purpose
            this.state.track = trackClone;              
        }   
    }

    clearTrack() {
        this.setState({track : [], trackTime:[]});        
    }

    handleInfoClick(e) {
       
    }

    render() {

        return (
            <FeatureGroup ref={this.platformRef}>
                <RotatedPlatform data={this.props.data} 
                    platformType={this.props.platformType} isActivePlatform={this.props.isActivePlatform} 
                    onInfoClick={this.handleInfoClick} />                                                               {/* Draw Platform */}
                <FramecenterIcon data={this.props.data} />                                                              {/* Draw Frame center */}
                <TargetMarker data={this.props.data} />                                                                 {/* Draw target */}
                <Footprint data={this.props.data} />                                                                    {/* Draw Footprint */}
                <Vmti data={this.props.data} show={this.props.showVmti} />                                              {/* Draw Vmti's Bounding Box and target center*/}
                <PlatformToFrameCenterLine data={this.props.data} />                                                    {/* Draw Platform To FrameCenter Line */}
                <PlatformToTargetLine data={this.props.data} />                                                         {/* Draw Platform To Target Line */}
                <Trail show={this.props.showTrail} trail={this.state.track} delay={this.props.trailDelay} />
            </FeatureGroup>)
    }
}


const PlatformsList = ({platforms, activePlatform, showVmti, showTrail, trailDuration }) => {

    PlatformsList.propTypes = {
        platforms: PropTypes.array,
        activePlatform: PropTypes.number,
        showVmti: PropTypes.bool,
        showTrail: PropTypes.bool,
        trailDuration: PropTypes.number
    }
    
    let items = null;
      
    if(platforms !== undefined && platforms.length > 0) {
        let index = 0;      
        items = platforms.map( platform => (       
                <Platform key={platform.name} name={platform.name} data={platform.klvs} platformType={platform.platformType} isActivePlatform={activePlatform === index++}
                 showVmti={showVmti} showTrail={showTrail} trailDuration={trailDuration}
                />
            ))

            return <div style={{ display: 'none' }}>{items}</div>
    } 
    else
        return null;
}

export default PlatformsList;
