import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import Grid from '@material-ui/core/Grid';
import { serverContext } from '../App';
import { useGetUsers, useGetUsergroups } from '../customEffects';
import OnlineUsersCount from './OnlineUsersCount';


const Line = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 1,
      borderStyle: 'none'
    }}
  />
);

const useStyles = makeStyles(theme => ({
  userActivity: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(6),
    color: 'primary',
  },  
}));


export default function UsersAndGroupsBrief() {

  const { server } = useContext(serverContext);

  useGetUsergroups();
  useGetUsers();

  const classes = useStyles();
  return (
    <div >
      <Grid container>
        <Grid item xs={4}>
          <Title>Users</Title>
        </Grid>
        <Grid item xs={8}>
          <OnlineUsersCount iconColor={"#3f51b5"} />
        </Grid>
      </Grid>
      <Typography component="p" variant="h5">
        {server.users ? server.users.length : 'N/A'}
      </Typography>
      <Link to="/users" color="primary">
       Edit Users
      </Link>
      <Link to="/useractivity" className={classes.userActivity}>
        User activity
      </Link>
      <Line color={'grey'} />
      <Title>User Groups</Title>
      <Typography component="p" variant="h5">
        {server.usergroups ? server.usergroups.length : 'N/A'}
      </Typography>
      <Link to="/usergroups">
       Edit User Groups
      </Link>
    </div>
  );
}
