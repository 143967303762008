import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import PlatformsBrief from './Components/PlatformBrief';
import UsersAndGroupsBrief from './Components/UsersAndGroupsBrief';
import ServerStatus from './Components/serverStatus';
import MissionTableLocal from './Components/MissionTableLocal';
//import { MissionTable } from 'stserver-frontend-comp';
import CreateMissionDlg from './CreateMissionDlg';
import LicenseBrief from './Components/licenseBrief';
import { appContext, serverContext, missionsContext, fetchFromServer, postToServer, putToServer, callServer, uploadToServer, postDownloadBlobFromServer, translateMissions, getFromServer } from './App';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));


/**
 *  Admin
 */
export default function Admin() {

    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [createMissionCtrl, setCreateMissionCtrl] = useState({ open: false, editMode: false });
    const [loading, setLoading] = useState(false);
    const { server } = useContext(serverContext);
    const { dispatchMissions } = useContext(missionsContext);
    const { dispatchApp } = useContext(appContext);
    const [selectedMission, setSelectedMission] = useState(null);
    const [queryParams, setQueryParams] = useState({});

    const handleOnCreateMission = async newMission => {
        try {
            setLoading(true);
            const missionToCreate = JSON.parse(JSON.stringify(newMission))
            missionToCreate.usergroups = newMission.usergroups.length > 0 ? newMission.usergroups.filter(g => g) : [];
            if(Array.isArray(missionToCreate.sensors))
                missionToCreate.sensors.forEach(s=>{delete s.tableData});
            
            await postToServer('missions', missionToCreate, server);
            setCreateMissionCtrl({ open: false, editMode: false });
            dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Mission ${newMission.name} added.` } });
            setLoading(false);

            if (newMission.sensors && Array.isArray(newMission.sensors) && newMission.sensors.length > 0) {

                for (const s of newMission.sensors) {

                    if(Array.isArray(s.files) && s.files.length > 0) {
                        try {
                            await uploadToServer(`missions/upload/${newMission.name}/${s.name}?processAfterUpload=immediate`, 'assets', s.files, server, progressEvent => {
                                //const progress = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(0);       
        
                                const msg = {
                                    mission: newMission.name,
                                    sensor: s.name,
                                    state: 'Processing',
                                    type: 'upload_progress',
                                    value: {
                                        loaded: progressEvent.loaded,
                                        total: progressEvent.total
                                    }
                                };
        
                                dispatchMissions({ type: 'UPDATE_MISSION', payload: msg });

                                // Update, so it would remove files from sensors for the next new mission                              
                               setSelectedMission({...selectedMission});        
                            });
                            
                        } catch (error) {
                            dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error uploading mission ${newMission.name}/${s.name}: ${error.message}` } });
                        }
                    }
                    else{
                        dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Warning', message: `${newMission.name}/${s.name} does not have files to upload` } });
                    }                   
                }
            }

        } catch (error) {
            setCreateMissionCtrl({ open: false, editMode: false });
            dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error adding mission: ${error.response ? error.response.data : error.message}` } });
            setLoading(false);
        }
    }

    const handleOnUpdateMission = async newMission => {
        try {
            setLoading(true);
            await putToServer('missions', newMission, server);
            setCreateMissionCtrl({ open: false, editMode: false });
            dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Mission ${newMission.name} updated.` } });
            setLoading(false);
        } catch (error) {
            setCreateMissionCtrl({ open: false, editMode: false });
            dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error updating mission: ${error.response ? error.response.data : error.message}` } });
            setLoading(false);
        }
    }


    const handleStartEditMission = async newMission => {

        if (!newMission) {
            dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Editing error - no mission selected` } });
            return;
        }

        try {
            setLoading(true);
            // Get mission
            const retMi = await fetchFromServer(`missions/${newMission.name}`, server);
            if (retMi && retMi.data) {
                const mi = retMi.data;

                // Get sensors
                const sensorsRet = await fetchFromServer(`sensors?missionIdOrName=${mi._id}`, server);
                const sensors = sensorsRet.status === 200 ? sensorsRet.data : [];

                // translate sensors (extract only info needed for edit)
                let editSensorsInfo = [];
                if (sensors.length > 0) {
                    editSensorsInfo = sensors.map(s => {
                        return {
                            name: s.name,
                            description: s.description,
                            type: s.type,
                            files: s.assets && s.assets.length > 0 ? s.assets.map(f => { return { path: f.path }; }) : []
                        }
                    })
                }

                mi.sensors = editSensorsInfo;
                setSelectedMission(mi);
                setCreateMissionCtrl({ open: true, editMode: true });
            }
          
            setLoading(false);
        } catch (error) {
            dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error editing mission ${newMission.name} : ${error.response.data}` } });
            setLoading(false);
        }
    }

    
    const handleExportMission = async missions => {

        if (!missions) {
            dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Export error - no mission selected` } });
            return;
        }

        const missionNames = missions.map(m => m.name);

        try {
            setLoading(true);
            dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Creating archive for ${missionNames.join(', ')}. Please wait...` } });
       
            const query = {
                missions: missionNames,
                archiveFormat: 'zip',
                content: 'processed'
            }
            const res = await postDownloadBlobFromServer('missions/export', query, server);

            const blob = new Blob([res.data], {type:`application/${query.archiveFormat}`});       
            const downloadUrl = URL.createObjectURL(blob);            
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = downloadUrl;
            a.download = `missionsArchive.${query.archiveFormat}`;         
            a.click()

            setSelectedMission(null);
            dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Missions ${missionNames.join(', ')} exported.` } });
            setLoading(false);
        } catch (error) {
            dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error exporting mission(s) ${missionNames.join(', ')} : ${error.response.data}` } });
            setLoading(false);
        }
    }

  const handleExportMissionList = async missions => {

        if (!missions) {
            dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Export error - no mission selected` } });
            return;
        }

        const missionNames = missions.map(m => m.name);

        try {
            setLoading(true);
            dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Creating mission list for ${missionNames.join(', ')}. Please wait...` } });
       
            const query = {                
                format: 'json',
                missions: missionNames,
                includeBookmarks: true
            };

            const res = await callServer('post', 'missions/exportList', query, server);
            const blob = new Blob([JSON.stringify(res.data, null, 2)], {type:`application/${query.format}`});       
            const downloadUrl = URL.createObjectURL(blob);            
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = downloadUrl;
            a.download = `missionList.${query.format}`;         
            a.click()

            setSelectedMission(null);
            dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Missions ${missionNames.join(', ')} exported.` } });
            setLoading(false);
        } catch (error) {
            dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error exporting mission(s) ${missionNames.join(', ')} : ${error.response.data}` } });
            setLoading(false);
        }
    }

    const handleStartDeleteMissions = async missions => {

        const missionNames = missions.map(m => m.name);

        try {
            setLoading(true);
            await callServer('delete', 'missions', { missions: missionNames }, server);
            setSelectedMission(null);
            dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Missions ${missionNames.join(', ')} deleted.` } });
            setLoading(false);
        } catch (error) {
            dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error deleting mission(s) ${missionNames.join(', ')} : ${error.response.data}` } });
            setLoading(false);
        }
    }

    const handleVersionUpdate = async ver => {   
        
        try {
            setLoading(true);
            await postToServer(`update?version=${ver}`, null, server);          
            dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Sever will be updated from version ${server.serverVersion} to ${ver}.` } });
            setLoading(false);
        } catch (error) {
            dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error updating version - ${error.response.data}` } });
            setLoading(false);
        }     
    }


    const handleApplyQueryFilter = async filter => {
        try {
            if (filter) {
                sessionStorage.setItem('queryFilter', JSON.stringify(filter));
                setQueryParams(filter);
            }
            else {
                setQueryParams({});
                sessionStorage.removeItem("queryFilter");
            }
        } catch (error) {

        }
     
        dispatchApp({ type: 'UPDATE_MISSIONS_FILTER', payload: filter });
        const resMissionList = await getFromServer(`missions/brief`, filter, server);
        dispatchMissions({ type: 'SET_MISSIONS', payload: translateMissions(resMissionList.data) });
    }


    return (
        <div >
            <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={3}>
                    <Paper className={fixedHeightPaper}>
                        <ServerStatus onVersionUpdate={handleVersionUpdate}/>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                    <Paper className={fixedHeightPaper}>
                        <UsersAndGroupsBrief />
                    </Paper>
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                    <Paper className={fixedHeightPaper}>
                        <PlatformsBrief />
                    </Paper>
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                    <Paper className={fixedHeightPaper}>
                        <LicenseBrief />
                    </Paper>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Paper elevation={1}>
                        <MissionTableLocal admin missionsFilter={queryParams} onAddMission={() => setCreateMissionCtrl({ open: true, editMode: false })} onEditMission={handleStartEditMission} onExportMission={handleExportMission}  onExportMissionList={handleExportMissionList} onDeleteMissions={handleStartDeleteMissions} onApplyQueryFilter={handleApplyQueryFilter} />
                        {/* <MissionTable admin serverUrl={server.serverHost} serverToken={server.token} missionsFilter={queryParams} onAddMission={() => setCreateMissionCtrl({ open: true, editMode: false })} onEditMission={handleStartEditMission} onExportMission={handleExportMission}  onExportMissionList={handleExportMissionList} onDeleteMissions={handleStartDeleteMissions} onApplyQueryFilter={handleApplyQueryFilter} /> */}
                    </Paper>
                </Grid>
            </Grid>
            <CreateMissionDlg open={createMissionCtrl.open} editMode={createMissionCtrl.editMode} mission={selectedMission} onClose={() => setCreateMissionCtrl({ open: false, editMode: false })} onCreateMission={handleOnCreateMission} onUpdateMission={handleOnUpdateMission} />
            {loading && <LinearProgress />}

        </div>
    );
}