import React from 'react';
import { makeStyles, withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import FilterListIcon from '@material-ui/icons/FilterList';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import Paper from '@material-ui/core/Paper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Tooltip from '@material-ui/core/Tooltip';
import orange from '@material-ui/core/colors/orange';


const theme = createTheme({

});


const useStyles = makeStyles((theme) => ({
    toolbarRoot: {
        marginTop: 20,
    },
    paper: {
        marginLeft: 10,
        display: 'flex',
        flexWrap: 'wrap',
    },
    divider: {
        margin: theme.spacing(1, 0.5),
    },
    filterSet: {
        color: orange[500]
    },
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        margin: theme.spacing(0.5),
        border: 'none',
        '&:not(:first-child)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-child': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}))(ToggleButtonGroup);



const countDocuments = (docs) => {
    return Array.isArray(docs) && docs.length > 0 ? docs.length : 0;
}


const DocumentsCounter = props => {

    const { data } = props;
    const classes = useStyles();

    return (
        <div className={classes.badge}>
            <MuiThemeProvider theme={theme}>
                <Tooltip title="Total documents">
                    <Badge badgeContent={countDocuments(data)} color="primary">
                        <Typography noWrap variant="overline" display="block">{props.title}</Typography>
                    </Badge>
                </Tooltip>
            </MuiThemeProvider>
        </div>
    )
}


/**
 * DocumentsTableToolbar 
 * Documents filters
 * @param {Object} props 
 */
const DocumentsTableToolbar = props => {

    const handleSelection = selection => {
        if (typeof props.onSelection === 'function') {
            props.onSelection(selection);
        }
    };

    const classes = useStyles();
    const { controls } = props;

    return (
        <div>
            <Grid container className={classes.toolbarRoot} >
                <Hidden only={['xs', 'sm']}>
                    <Grid item >
                        <DocumentsCounter title='Documents' data={props.documents} />
                    </Grid>
                </Hidden>
                <Hidden only={['xs']}>
                    <Grid item >
                        <Paper elevation={0} className={classes.paper}>
                            <StyledToggleButtonGroup
                                size="small"
                                exclusive
                                onChange={() => handleSelection('filter')}
                            >
                                <ToggleButton value='filter' selected={controls.filter}  >
                                    <Tooltip title="Filter">
                                        <FilterListIcon className={props.documentsFilter.filter && classes.filterSet} />
                                    </Tooltip>
                                </ToggleButton>
                            </StyledToggleButtonGroup>
                            <StyledToggleButtonGroup
                                size="small"
                                exclusive
                                onChange={() => handleSelection('time')}
                            >
                                <ToggleButton value='time' selected={controls.time} >
                                    <Tooltip title="Time">
                                        <AccessTimeIcon className={props.documentsFilter.time && classes.filterSet} />
                                    </Tooltip>
                                </ToggleButton>
                            </StyledToggleButtonGroup>
                            <StyledToggleButtonGroup
                                size="small"
                                exclusive
                                onChange={() => handleSelection('tags')}
                            >
                                <ToggleButton value='tags' selected={controls.tags}>
                                    <Tooltip title="Tags">
                                        <LabelOutlinedIcon className={props.documentsFilter.tags && classes.filterSet} />
                                    </Tooltip>
                                </ToggleButton>
                            </StyledToggleButtonGroup>
                            <StyledToggleButtonGroup
                                size="small"
                                exclusive
                                onChange={() => handleSelection('usergroups')}
                            >
                                <ToggleButton value='usergroup' selected={controls.usergroups} >
                                    <Tooltip title="User Group">
                                        <PeopleOutlineIcon className={props.documentsFilter.usergroups && classes.filterSet} />
                                    </Tooltip>
                                </ToggleButton>
                            </StyledToggleButtonGroup>
                        </Paper>
                    </Grid>
                </Hidden>
            </Grid>
        </div>
    );
}


export default DocumentsTableToolbar; 