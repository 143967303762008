import React from 'react';
import PropTypes from 'prop-types';
import { useFetchImageFromVideoServer } from '../customEffects';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import PartialMissionState from '../Images//partialState.png';
import ImageIcon from '@material-ui/icons/Image';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        maxWidth: 80,
        justify: "space-between"
    },
    state: {
        marginLeft: -1,
        fontWeight: 'fontWeightMedium',
        fontSize: 12,
        color: grey[500]
    },
    fi: {
        margin: 1,
        maxWidth: 28,
        maxHeight: 28,
        color: '#fff',
        backgroundColor: grey[200],
    },
    greyAvatar: {
        margin: 1,
        maxWidth: 28,
        maxHeight: 28,
        color: '#fff',
        backgroundColor: grey[400],
    },
    greenAvatar: {
        margin: 1,
        maxWidth: 28,
        maxHeight: 28,
        color: '#fff',
        backgroundColor: green[500],
    },
    orangeAvatar: {
        margin: 1,
        maxWidth: 28,
        maxHeight: 28,
        color: '#fff',
        backgroundColor: orange[300],
    },
    recorderOff: {
        maxWidth: 28,
        maxHeight: 28,
        backgroundColor: grey[200],
    },
    recorderError: {
        maxWidth: 28,
        maxHeight: 28,
        backgroundColor: red[500],
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    large: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    }
}));


export const AvatarByState = props => {
    const classes = useStyles();
    const state = props.state;
    if(state) {
        switch (props.state.toLowerCase()) {
            case 'unknown':
                return (<Tooltip title={`State unknown`} aria-label="Add">
                    <Avatar className={classes.greyAvatar}>?</Avatar>
                </Tooltip>)
            case 'online':
                return (<Tooltip title={`Online`} aria-label="Add">
                    <Avatar className={classes.greenAvatar}> </Avatar>
                </Tooltip>);
            case 'offline':
                return (<Tooltip title={`Offline`} aria-label="Add">
                    <Avatar className={classes.greyAvatar}> </Avatar>
                </Tooltip>);
            case 'lost':
                return (<Tooltip title={`Lost`} aria-label="Add">
                    <Avatar className={classes.orangeAvatar}> </Avatar>
                </Tooltip>);
            case 'partial':
                return <img src={PartialMissionState} alt="PartialState" />;
    
            default:
                return (<Tooltip title={`Offline`} aria-label="Add">
                    <Avatar className={classes.greyAvatar}>N</Avatar>
                </Tooltip>);
        }
    }   
}

const ShowState = props => {

    const classes = useStyles();
    return (
        <Grid container className={classes.root} onClick={() => { if (props.onClick) props.onClick() }} >
            <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center" justify="center" >
                    <Grid item xs={5}>
                        {
                            props.showBadge ?
                                <Tooltip title={`Sensor events`} aria-label="Add">
                                    <Badge className={classes.badge} badgeContent={props.eventCount} color="error">
                                        <AvatarByState state={props.state} />
                                    </Badge>
                                </Tooltip> :
                                <AvatarByState state={props.state} />
                        }
                    </Grid>
                    {/* <Hidden smDown> */}
                    {
                        !props.hideStateText ?
                            <Grid item xs={6}>
                                <Typography className={classes.state}>{props.state}</Typography>
                            </Grid>
                            : null
                    }
                    {/* </Hidden> */}
                </Grid>
            </Grid>
        </Grid>
    );
}

ShowState.propTypes = {
    state: PropTypes.string.isRequired
};


const ProcessingProgress = props => {
    const { updateInfo } = props;
    let progressValue;
    let tooltip = 'Processing...';
    let color = 'primary';
    let variant = 'indeterminate';

    if (updateInfo) {

        const { type, value } = updateInfo;
        switch (type) {

            case 'upload_progress':
                tooltip = `Uploaded ${Math.round(value.loaded / 1024 / 1024)}Mb / ${Math.round(value.total / 1024 / 1024)}Mb`;
                progressValue = Math.round(((value.loaded / value.total) * 100));
                color = 'secondary';
                variant = 'static';
                break;

            case 'hlsDetection':
                progressValue = 0;
                color = 'secondary';
                variant = 'static';
                break;

            case 'extractingArchive':
                progressValue = 0;
                color = 'secondary';
                variant = 'static';
                break;

            case 'hlsProcessing':
                const filename = value.path.replace(/^.*[\\\/]/, '')
                tooltip = `Segment ${filename}`;
                progressValue = Math.round(((value.accumulatedDuration / value.sourceDuration) * 100));               
                color = 'secondary';
                variant = 'static';
                break;

            case 'hlsProcessingComplete':
                progressValue = 100;
                variant = 'static';
                break;

            case 'ingest_start':
                tooltip = `Ingesting ${value.totalSegments} segments`;
                progressValue = 0;
                color = 'primary'
                variant = 'static';
                break;

            case 'ingest_progress':
                tooltip = `Ingested  ${value.segmentName} (${value.currentIndex} / ${value.totalSegments} segments)`;
                progressValue = Math.round(((value.currentIndex / value.totalSegments) * 100));
                color = 'primary'
                variant = 'static';
                break;

            case 'ingest_complete':
                tooltip = `Ingest complete - ${value.totalSegments} segments`;
                progressValue = 100;
                color = 'primary'
                variant = 'static';
                break;

            case 'areaCalculation':
                if (value.phase === 'start') {
                    tooltip = 'Calculate area...';
                    color = 'primary'
                    variant = 'indeterminate';
                }
                else {
                    tooltip = `${value.area?.toFixed(1)} sq. km`;
                    progressValue = 100;
                    color = 'primary'
                    variant = 'static';
                }
                break;

            default:
                console.log(`Unknown action type: ${type}`)
                break;
        }
    }

    return (
        // <MuiThemeProvider theme={theme}>
        <Box position="relative" display="inline-flex">
            <Tooltip title={tooltip}>
                <CircularProgress
                    variant={variant}
                    size={28}
                    value={progressValue}
                    color={color}
                    disableShrink={true}
                    thickness={4}
                />
            </Tooltip>
            {progressValue !== undefined &&
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize={9}
                >
                    {`${progressValue}%`}
                </Box>
            }
        </Box>
        // </MuiThemeProvider>
    )
}

ProcessingProgress.propTypes = {
    updateInfo: PropTypes.object
};


export const StateCell = props => {
    const { state, missionName, serverUrl, thumbnail, videoServerToken, update } = props;
    const { image } = useFetchImageFromVideoServer(`${serverUrl}/videos/${missionName}/${thumbnail}`, videoServerToken, update);
   
     
    const classes = useStyles();

    switch (state) {

        case 'Created':
            return (
                <Avatar variant="rounded" title="Mission Created" className={classes.small}>
                    <ImageIcon />
                </Avatar>
            );

        case 'Processing':
            return <ProcessingProgress updateInfo={update} />

        case 'Ready':
            return (thumbnail ?
                <Avatar variant="rounded" className={classes.small} alt={`${thumbnail}`} src={image}/>
                :
                <Avatar className={classes.small}>R</Avatar>);

        case 'Running':
            return <ProcessingProgress updateInfo={update} />

        case 'WaitingForData':
            return (
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    size={24}
                    thickness={4}
                />);

        case 'Stopped':
            return <Avatar className={classes.small} ></Avatar>;

        default:
            return null;
    }
}


export const RecorderState = props => {
    const rowData = props.data;
    const classes = useStyles();
    const recorderInfo = props.data.recorderInfo;
   
    if (rowData.live) {
        return ( 
            recorderInfo ? (

                recorderInfo.error ?
                <Tooltip title={recorderInfo.error} >
                    <Avatar className={classes.recorderError}>
                        <ErrorIcon />
                    </Avatar>
                </Tooltip> 
                :
                <Tooltip title={recorderInfo.info ? `${recorderInfo.info.state}` :''} >
                    <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        size={24}
                        thickness={4}
                    />
                </Tooltip>
            ) :
                <Tooltip title={`Running / ${rowData.state}`} >
                    <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        size={24}
                        thickness={4}
                    />
                </Tooltip>
        )
    }
    else
        return (
            <Tooltip title="Off" >
                <Avatar className={classes.recorderOff}> </Avatar>
            </Tooltip>
        );
}


export default ShowState; 