import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { serverContext } from './App';
import { checkUserAuthorizationForRestricted } from './utils';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SelectUsergroups from './Components/SelectUsergroups'
import TagsArray from './Components/TagsArray.js';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { securityType, documentType } from './commonTypes';
import { useDropzone } from 'react-dropzone';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useTags } from './customEffects';
import DocumentPreview from './DocumentPreview';


const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  formControl: {
    minWidth: 180,
  },
  formControlTags: {
    marginLeft: -8,
  },
  formControlRestricted: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  progress: {
    marginTop: 4,
    width: '100%',
  },
}));


const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" {...props} ref={ref} />;
});


const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};


const DropzoneDocument = props => {

  const [files2Upload, setFiles2Upload] = useState([]);

  const onDrop = useCallback(acceptedFiles => {

    setFiles2Upload(acceptedFiles);

    if (acceptedFiles && props.onFileDrop)
      props.onFileDrop(acceptedFiles);

  }, [props])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    multiple: false,
    accept: [
      'text/plain',
      'text/csv',
      'image/png',
      'image/jpeg',
      'application/pdf',
      'application/zip',
      'application/x-zip-compressed',
      'application/x-zip',
      'application/x-winzip',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'video/mp2t', 'video/mp4',
      '.csv', '.bin', 'application/x-binary',
      'application/json',
    ]
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [isDragActive, isDragReject, isDragAccept]);

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop a file, or click to select it</p>
      </div>
      <aside>
        {files2Upload.map(file => (
          <li key={file.name}>{file.name}</li>
        ))}
      </aside>
    </div>
  )
}


const validationSchema = Yup.object({
  title: Yup.string()
    .matches(/^[a-zA-Z0-9 _-]+$/, 'Title can only contain letters, numbers, spaces, underscores, and hyphens')
    .max(16, 'Title must be 16 characters or less')
    .required('Title is required'),
  description: Yup.string()
    .max(127, 'Description must be 127 characters or less'),
  documentType: Yup.string().required('Document Type is required')
    .oneOf(documentType.map(doc => doc.value), 'Invalid document type'),
  restricted: Yup.boolean(),
  securityClassification: Yup.string().oneOf(securityType.map(sec => sec.value), 'Invalid security classification')
});


const createInitialValues = {
  title: '',
  description: '',
  documentType: 'Note',
  securityClassification: 'UNCLASSIFIED',
  restricted: false,
};

/**
 * CreateDocumentDlg renders a dialog that accepts the file and its metadata .
 * The Ok button is disabled until the form is valid.
 */
function CreateDocumentDlg(props) {
  const { server } = useContext(serverContext);
  const [selectedUserGroups, setSelectedUserGroups] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [documentFile, setDocumentFile] = useState(null);
  const [progress, setProgress] = React.useState(0);
  const [showProgress, setShowProgress] = React.useState(false);
  const { tags } = useTags(server.serverHost, server.token);
  const [restrictedEditDisabled, setRestrictedEditDisabled] = useState(true);

  const theme = useTheme();
  const classes = useStyles();

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const formik = useFormik({
    initialValues: props.mode === 'create' ? createInitialValues : {
      title: props.document.title,
      description: props.document.description,
      documentType: props.document.documentType,
      securityClassification: props.document.securityClassification,
      restricted: props.document.restricted,
    },
    validationSchema: validationSchema,
    validateOnMount: true,
  });

  useEffect(() => {
    const updateValues = async () => {
      if (formik.dirty) {
        try {
          await validationSchema.validate(formik.values, { abortEarly: false });

        } catch (validationErrors) {
          const errors = {};
          validationErrors.inner.forEach((error) => {
            errors[error.path] = error.message;
          });
          formik.setErrors(errors);
        }
      }
    };
    updateValues();
  }, [formik.values, formik.dirty]);

  useEffect(() => {
    if (props.mode === 'edit') {
      if (server) {
        const ugroups = server.usergroups.filter(g => props.document?.usergroups.includes(g._id));
        setSelectedUserGroups(ugroups || []);
        setSelectedTags(props.document.tags || []);
        const enabled = checkUserAuthorizationForRestricted(server, props.document);
        setRestrictedEditDisabled(!enabled);
      }
    } else {
      setRestrictedEditDisabled(server.userRole !== 'superAdmin' && server.userRole !== 'admin' && server.userRole !== 'groupAdmin');
    }
  }, [props, props.mode, props.document, server, server.userRole]);





  // Trigger form submission when Ok is pressed.
  const handleCreateDocument = async () => {

    try {

      setShowProgress(true);
      const formData = new FormData();
      formData.append('title', formik.values.title);
      formData.append('description', formik.values.description);
      formData.append('documentType', formik.values.documentType);
      formData.append('securityClassification', formik.values.securityClassification);
      formData.append('restricted', formik.values.restricted);
      formData.append('usergroups', selectedUserGroups.map(group => group._id));
      formData.append('tags', selectedTags);
      formData.append('file', documentFile);

      let response;
      if (props.mode === 'create') {
        response = await axios({
          method: 'post',
          url: `${server.serverHost}/api/documents`,
          data: formData,
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
          headers: {
            'Authorization': `Basic ${server.token}`,
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: progressEvent => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(percentCompleted);
          }
        });
      } else {

        const data = { ...formik.values, usergroups: selectedUserGroups.map(group => group._id), tags: selectedTags };

        response = await axios({
          method: 'put',
          url: `${server.serverHost}/api/documents/${props.document._id}`,
          data: data,
          headers: {
            'Authorization': `Basic ${server.token}`,
          }
        });
      }

      console.log('Document created successfully:', response.data);
      props.onClose(); // Close the dialog after successful submission
    } catch (error) {
      console.error('Error creating document:', error);
    } finally {
      setShowProgress(false);
    }
  };

  const handleUserGroupsChange = ugs => {
    setSelectedUserGroups(ugs);
  }

  const handleTagsChange = selectedTags => {
    setSelectedTags(selectedTags);
  }


  const handleDocumentFileDrop = files => {

    const file = files[0];
    setDocumentFile(file);

    if (file && file.type.startsWith('image/')) {
      formik.setFieldValue('documentType', 'Image');
    } else if (file && file.type.startsWith('video/')) {
      formik.setFieldValue('documentType', 'Video');
    } else if (file && file.name.endsWith('.ts')) {
      formik.setFieldValue('documentType', 'Video');
    }
  }

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      onClose={props.onClose}
      fullWidth={true}
      fullScreen={fullScreen}
    >
      <DialogTitle>{`${props.mode === 'create' ? ' 📄 Create' : '📝 Edit'} Document`}</DialogTitle>
      <DialogContent style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div className={classes.root} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
          {props.mode === 'create' ?
            <DropzoneDocument onFileDrop={handleDocumentFileDrop} /> : <DocumentPreview document={props.document} />
          }
          <div style={{ display: "flex", flexDirection: "column", gap: "16px", flexGrow: 1, marginTop: 16 }}>
            <div style={{ display: "flex", width: "100%", gap: "16px" }}>
              <FormControl className={classes.formControl} style={{ flex: 7 }}>
                <TextField
                  label="Title"
                  variant="standard"
                  fullWidth
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="title"
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </FormControl>
              <FormControl style={{ flex: 3 }}>
                <InputLabel>Document Type</InputLabel>
                <Select
                  fullWidth
                  value={formik.values.documentType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="documentType"
                >
                  {documentType.map(doc => (
                    <MenuItem key={doc.value} value={doc.value}>{doc.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <FormControl className={classes.formControl} style={{ width: "100%" }}>
              <TextField
                label="Description"
                variant="standard"
                fullWidth
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="description"
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description ? formik.errors.description : ''}
              />
            </FormControl>
            <div style={{ display: "flex", width: "100%", gap: "16px" }}>
              <FormControl className={classes.formControl} style={{ flex: 7 }}>
                <InputLabel>User Groups</InputLabel>
                <SelectUsergroups
                  canDeleteDemo
                  selectedUserGroups={selectedUserGroups}
                  onUserGroupsChange={handleUserGroupsChange}
                />
              </FormControl>
              <FormControl className={classes.formControl} style={{ flex: 3 }}>
                <InputLabel>Security Classification</InputLabel>
                <Select
                  fullWidth
                  value={formik.values.securityClassification}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="securityClassification"
                >
                  {securityType.map(sec => (
                    <MenuItem key={sec.value} value={sec.value}>{sec.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ display: "flex", width: "100%", gap: "16px" }}>
              <FormControl className={classes.formControlTags} style={{ flex: 7 }}>
                <TagsArray tags={tags.map(tag => tag.title)} selectedTags={selectedTags} onChange={(event, newValue) => handleTagsChange(newValue)} />
              </FormControl>
              <FormControl className={classes.formControlRestricted} style={{ flex: 3 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.restricted}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="restricted"
                      color="secondary"
                    />
                  }
                  disabled={restrictedEditDisabled}
                  label="Restricted"
                  labelPlacement="start"
                />
              </FormControl>
            </div>
          </div>
          {
            showProgress && <LinearProgress variant="determinate" value={progress} />
          }
          <DialogActions style={{ justifyContent: "flex-end", marginTop: "auto" }}>
            <Button onClick={props.onClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleCreateDocument} color="secondary" disabled={!formik.isValid || (props.mode === 'create' && documentFile === null)}>
              Ok
            </Button>
          </DialogActions>
        </div>
      </DialogContent>
    </Dialog>
  );
}


CreateDocumentDlg.propTypes = {
  onClose: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['create', 'edit']).isRequired,
  onCreateDocument: PropTypes.func,
};


export default CreateDocumentDlg;
