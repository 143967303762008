import React, { useState, useEffect, forwardRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { BookmarkForm } from 'stserver-frontend-comp';
import { captureCurrentFrame } from './utils';


const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" {...props} ref={ref} />;
})

// const captureCurrentFrame = (player, scaleFactor) => {
//     if (scaleFactor == null) {
//         scaleFactor = 1;
//     }
//     const w = player.currentDimension("width") * scaleFactor;
//     const h = player.currentDimension("height") * scaleFactor;
//     const canvas = document.createElement('canvas');
//     canvas.width = w;
//     canvas.height = h;
//     const ctx = canvas.getContext('2d');
//     ctx.drawImage(player.children_[0], 0, 0, w, h);
//     return canvas;
// }

/**
 * @param  {} props
 * open 
 * editMode
 * mission      - mission mode
 * platform     - platform mode. This mode is used when we have to create live mission for the selected platform. Only some of the field can be edited.
 * onClose
 * onCreateBookmarkMission
 */
function CreateBookmarkDlg(props) {

    const [curBookmark, setCurBookmark] = useState({ tags: [] });
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [bookmarkError, setBookmarkError] = useState({});

    useEffect(() => {

        if (props.player) {

            let tm; 
            if(props.player.getSnapshot) {
              tm = props.player.getSnapshot().toDataURL('image/jpeg', 0.8);
            } else {
              tm =  captureCurrentFrame(props.player, 1).toDataURL('image/jpeg', 0.8);
            }

            setCurBookmark({
                thumbnailUrl: tm,
                position: props.player.currentTime().toFixed(2),
                startTime: props.timestamp
            })
        }
    }, [])

    const handleCreateBookmark = () => {

        if (!curBookmark.title || curBookmark.title.length === 0) {
            setBookmarkError({ ...bookmarkError, titleError: 'Empty title' });
            return;
        }

        props.onCreateBookmark(curBookmark);
    }


    const handleBookmarkUpdate = bm => {
        if (bm.title && bm.title.length > 0)
            setBookmarkError({ ...bookmarkError, titleError: undefined });
        setCurBookmark(bm);
    }


    return (

        <Dialog
            open={true}
            TransitionComponent={Transition}
            onClose={() => props.onClose()}
            fullWidth={true} fullScreen={fullScreen}
        >
            <DialogTitle>
                Create Bookmark
            </DialogTitle>
            <DialogContent>
                <div style={{ textAlign: "left" }}>
                    <BookmarkForm bookmark={curBookmark} bookmarkError={bookmarkError} onBookmarkUpdate={handleBookmarkUpdate} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()} color="primary">
                    Cancel
                </Button>
                <Button type="submit" color="primary" onClick={() => handleCreateBookmark()} >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateBookmarkDlg;