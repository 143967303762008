import React, { useState, useEffect, useMemo, useContext, forwardRef } from 'react';
import { MuiThemeProvider, createTheme, makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table'
import LinearProgress from '@material-ui/core/LinearProgress';
import { serverContext, appContext, platformsContext, translatePlatforms } from '../App';
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Fab from '@material-ui/core/Fab';
import RecordSensorIcon from '@material-ui/icons/FiberManualRecord';
import StopIcon from '@material-ui/icons/Stop';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Popover from '@material-ui/core/Popover';
import Hidden from '@material-ui/core/Hidden';
import grey from '@material-ui/core/colors/grey';
import { Tooltip } from '@material-ui/core';
import { RecorderState, AvatarByState } from './showState';
import moment from 'moment';
import MissionOrPlatformCounter from './MissionOrPlatformCounter';
import { SensorPlayer } from 'stserver-frontend-comp';


const tableIcons = {
  AddIcon: forwardRef((props, ref) => <AddIcon {...props} ref={ref} style={{ color: 'blue' }}  />),
  DeleteIcon: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};


const theme = createTheme({
  palette: {
    primary: {
      main: '#4caf50',
    },
    secondary: {
      main: '#47A3E8',
    },
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    // '& > *': {
    //   margin: theme.spacing(1),
    // },
    '& .MuiPaper-root': {
      boxShadow: 'none'
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  large: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: 0,
  },
  mediaRoot: {
    width: 180,
  },
  media: {
    height: 128,
  },
  video: {
    width: 180,
    height: 128,
    elevation: 0,
    backgroundColor: grey[100],
    textAlign: 'left',
    justify: 'center'
  },
}));



function getLiveMode(useLiveLowLatency, useWebRtcVideo, useLiveRtspHls) {
  let liveMode;

  if (useLiveLowLatency)
    liveMode = 'lowLatency';
  else {
    if (useWebRtcVideo)
      liveMode = 'webRtc';
    else if (useLiveRtspHls)
      liveMode = 'highQuality';
    else
      liveMode = 'highQualityDvr';
  }
  return liveMode;
}

const LiveSensorPopover = props => {
  const classes = useStyles();
  const { server } = useContext(serverContext);
  const { data } = props;


  const liveMode = useMemo(() => {
    return getLiveMode(server.useLiveLowLatency, server.useWebRtcVideo, server.useLiveRtspHls);
  }, [server.useLiveLowLatency, server.useWebRtcVideo,  server.useLiveRtspHls]);

  return (
    <Card className={classes.mediaRoot} id='JsPlayer'>
      <CardActionArea>
        <CardContent className={classes.video} >
          <SensorPlayer
            mode={liveMode}
            webRtcService={server.webRtcService}
            autoplay={true}
            showControls={false}
            wsVideoStreamPort={data.config.wsVideoStreamPort}                  
            platformName={data.parentPlatformName}
            platformType={data.parentPlatformType}
            sensorName={data.sensor}        
            serverUrl={server.serverHost}
            useReverseProxy={server.useReverseProxy}
            token={server.token}
            clientId={server.clientId}
            username={server.username}
            password={server.password}         
            aspectRatioChangeBehaviour={'keepWidth'}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}


const RecordMissionIcon = () => {
  return (
    <svg height="24" width="24">
      <circle cx="12" cy="12" r="11" stroke="lightgrey" strokeWidth="1" fill="red" />
    </svg>
  )
}

const PlatformRecorderControl = rowData => {
  return rowData.live ? <StopIcon /> : <RecordMissionIcon disabled />
}

const SensorRecorderControl = rowData => {

  return rowData.live ? <StopIcon /> : <RecordSensorIcon color="error" />
}

const RecorderButtonFab = (rowData, userRoleAdmin, cb) => {

  const type = rowData.parentId === undefined ? 'platform' : 'sensor';

  let isDisabled = !userRoleAdmin;
  //TODO: Need to finish the dev
  // if (type === 'platform' && userRoleAdmin) {
  //   const checkedChildren = rowData.tableData.childRows.filter(ch => ch.checked);
  //   isDisabled = checkedChildren.length === 0;
  // }

  const tooltip = type === 'platform' ? `${!rowData.live ? 'Start' : 'Stop'} selected sensors` : `${!rowData.live ? 'Start' : 'Stop'}  sensor`;

  return (
    <Tooltip title={tooltip}>
      <Fab size="small" disabled={isDisabled} onClick={() => {

        if (cb) {
          const platformOrSensor = type === 'platform' ? {
            type: type,
            platformType: rowData.type,
            live: rowData.live,
            platformName: rowData.platform,
            sensors: rowData.tableData.childRows.map(s => { return { sensorName: s.name, checked: s.tableData.checked || false } }),
            checked: rowData.tableData.checked || false
          } :
            {
              type: type,
              live: rowData.live,
              platformName: rowData.parentPlatformName,
              platformType: rowData.platformType,
              platformLive: rowData.parentPlatformLive,
              sensorName: rowData.sensor,
              checked: rowData.tableData.checked || false
            };

          cb(platformOrSensor);
        }
      }} >
        {type === 'platform' ? PlatformRecorderControl(rowData) : SensorRecorderControl(rowData)}
      </Fab>
    </Tooltip>
  )
}

const PlatformState = props => {
  const rowData = props.data;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {

    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();
  const open = Boolean(anchorEl) && rowData.sensor !== undefined && rowData.config && rowData.config.serverHostName !== null; // Open only for sensor

  return (
    <div
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}>
      <AvatarByState state={rowData.state} />
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <LiveSensorPopover data={rowData} />
      </Popover>
    </div>
  )
}



/**
 * Create table columns based on mode.
 * @param  {} admin
 * @param  {} cb
 */
const getColumns = (admin, userRoleAdmin, cb) => {

  const columns = [];

  if (admin) {
    columns.push({
      title: 'Record', field: 'state', tooltip: 'Record', width: '5%',
      render: rowData => { return RecorderButtonFab(rowData, userRoleAdmin, cb) }
    })
  }

  return [...columns,
  {
    title: 'State', field: 'state', tooltip: 'State', width: '5%',
    render: rowData => <PlatformState data={rowData} />
  },
  {
    title: 'Rec', field: 'recorder', tooltip: 'Recorder', width: '5%',
    render: rowData => <RecorderState data={rowData} />
  },
  { title: 'Name', field: 'name', tooltip: 'Platform name' },
  { title: 'Description', field: 'description', tooltip: 'Platform Description' },
  { title: 'Type', field: 'type', tooltip: 'Platform Type' },
  {
    title: 'Start Time', field: 'recordingStartTime', render: rowData => {
      if (rowData.live)
        return (rowData.recordingStartTime !== undefined ? moment(rowData.recordingStartTime).format("MMM DD YYYY, HH:mm") : 'N/A')
      else
        return 'N/A';
    }
  },
  {
    title: 'Duration', field: 'duration', render: rowData => { return (rowData.duration !== undefined ? rowData.duration : 'N/A') }
  },
  {
    title: 'Segments', field: 'segments', tooltip: 'Recorded segments', render: rowData => {
      const recorderInfo = rowData.recorderInfo ? rowData.recorderInfo.info : undefined;
      if (recorderInfo && rowData.live) {
        return recorderInfo.totalSegments ? recorderInfo.totalSegments : 'N/A';
      }
      else
        return '';
    }
  },
  ];
}

// Missions are provided in a form suitable for the material table, so we need to count the ones with no parentId
// const countMissionsOrPlatforms = mp => {
//   return Array.isArray(mp) && mp.length > 0 ? mp.reduce((total, m) => total + (m.parentId !== undefined ? 0 : 1), 0) : 0;
// }


const PlatformTable = props => {

  const { server } = useContext(serverContext);
  const { platforms } = useContext(platformsContext);
  const [curTranslatedPlatforms, setCurTranslatedPlatforms] = useState([]);
  const { dispatchApp } = useContext(appContext);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);


   const actions = [];
  // if (props.admin) {

  //   actions.push({
  //     icon: tableIcons.Edit,
  //     tooltip: 'Edit Live Mission',
  //     isFreeAction: true,
  //     onClick: event => {
  //       if (!selectedRow)
  //         dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Please select mission to edit...`, severity: 'warning' } });
  //       else {

  //         if (props.onEditMission && selectedRow.parentId === undefined)
  //           props.onEditMission(selectedRow);
  //       }
  //     }
  //   })
  // }


  const updateLiveDuration = () => {

    if (platforms.availablePlatforms.length > 0) {
      const translatedPlatforms = translatePlatforms(platforms.availablePlatforms);

      const updatedPlatforms = translatedPlatforms.map(p => {

        if (p.live && p.recordingStartTime !== 'N/A') {
          const d = moment.duration(moment().diff(p.recordingStartTime));
          return { ...p, duration: d.humanize() }
        }
        else {
          return { ...p };
        }

      })
      setCurTranslatedPlatforms(updatedPlatforms);
    }
    else
      setCurTranslatedPlatforms([]);
  }


  useEffect(() => {

    updateLiveDuration();
    const interval = setInterval(() => {
      updateLiveDuration();
    }, 10000);

    return () => clearInterval(interval);
  }, [platforms]);



  useEffect(() => {
    if (Array.isArray(platforms.availablePlatforms) && platforms.availablePlatforms.length > 0) {
      const translatedPlatforms = translatePlatforms(platforms.availablePlatforms);
      setCurTranslatedPlatforms(translatedPlatforms);
    }

  }, [platforms.stateUpdateTime]);


  const handleRecordClick = async platformOrSensor => {

    try {
      setLoading(true);

      if (props.onCommand)
        props.onCommand('record', platformOrSensor);

      let message = `Platform ${platformOrSensor.platformName}`;
      if (platformOrSensor.type === 'sensor')
        message = message.concat(` Sensor ${platformOrSensor.sensorName}`);

      dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: message, severity: 'info' } });
      setLoading(false);
    } catch (error) {
      dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error recording mission: ${error.response ? error.response.data : error.message}` } });
      setLoading(false);
    }
  }

  const handleSelectedRow = (evt, selectedRow) => {

    setSelectedRow(selectedRow)

    // For sensors
    if (props.onSelectedLiveSensors && selectedRow.parentId !== undefined) {
      const sensors = [selectedRow];
      props.onSelectedLiveSensors(sensors);
    }

    // For entire platform
    if (props.onSelectPlatform && selectedRow.parentId === undefined) {

      const sensors = selectedRow.tableData.childRows;
      props.onSelectedLiveSensors(sensors);

      const platform = { ...selectedRow, sensors: { ...sensors } };
      delete platform.tableData;

      props.onSelectPlatform(platform);
    }
  }

  const handleCheckbox = rows => {

    // curTranslatedPlatforms.forEach(p=> {

    //   for(const r of rows) { 
    //     if( p.name &&  p.name === r.platform ) {
    //       p.checked = r.tableData.checked;
    //     }

    //     if(p.sensor && p.sensor === r.sensor){
    //       p.checked = r.tableData.checked;
    //     } 
    //   }
    // })

    // const updatedCheckedRows = checkedRows.map(r => {



    // })

    // const newChecked = checkedRows.length > 0 ? [...checkedRows, ...rows] : rows;

    //  setCheckedRows(newChecked);  
  }

  const userRoleAdmin = server.userRole === 'superAdmin' || server.userRole === 'admin' || server.userRole === 'groupAdmin';
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          icons={tableIcons}
          columns={getColumns(props.admin, userRoleAdmin, handleRecordClick)}
          data={curTranslatedPlatforms}
          title={
            <Hidden only={['xs', 'sm']}>
              <MissionOrPlatformCounter title='Platforms' data={curTranslatedPlatforms} />
            </Hidden>
          }
          parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
          onRowClick={handleSelectedRow}
          onSelectionChange={handleCheckbox}
          options={{
            cellStyle: { padding: '0.5em' },
            headerStyle: {
              padding: '0.5em',
              backgroundColor: '#EEE'
            },
            //  selection: props.admin,
            //  selectionProps: rowData => ({    
            //    disabled: rowData.tableData.childRows ? false : true,       
            //    checked: rowData.checked === true,  
            //    color: 'primary'
            // }),
            defaultExpanded: true,
            rowStyle: rowData => ({
              backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#EFFBFF' : '#FFF',
            })
          }}
          actions={actions}
        />
        {loading && <LinearProgress />}
      </MuiThemeProvider>
    </div>
  );
}



export default PlatformTable;
