import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import MovieCreationOutlinedIcon from '@material-ui/icons/MovieCreationOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import SubjectIcon from '@material-ui/icons/Subject';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import CommentIcon from '@material-ui/icons/Comment';



const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        maxWidth: 80,
        justify: "space-between"
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));





export const FormatCell = props => {
    const { documentType } = props;

    const classes = useStyles();

    switch (documentType) {

        case 'Report':
            return (
                <Avatar variant="rounded" title="Report" className={classes.small}>
                    <DescriptionIcon />
                </Avatar>
            );
        case 'Note':
            return (
                <Avatar variant="rounded" title="Note" className={classes.small}>
                    <SpeakerNotesIcon />
                </Avatar>
            );
        case 'Document':
            return (
                <Avatar variant="rounded" title="Note" className={classes.small}>
                    <CommentIcon />
                </Avatar>
            );
        case 'Image':
            return (
                <Avatar variant="rounded" title="Image" className={classes.small}>
                    <ImageIcon />
                </Avatar>
            );
        case 'Video':
            return (
                <Avatar variant="rounded" title="Video" className={classes.small}>
                    <MovieCreationOutlinedIcon />
                </Avatar>
            );
        case 'Log':
            return (
                <Avatar variant="rounded" title="Log" className={classes.small}>
                    <SubjectIcon />
                </Avatar>
            );
        case 'Misc':
            return (
                <Avatar variant="rounded" title="Misc" className={classes.small}>
                    <ClearAllIcon />
                </Avatar>
            );

        default:
            return null;
    }
}

FormatCell.propTypes = {
    documentType: PropTypes.string.isRequired,
};
