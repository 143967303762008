import React, { useState, useEffect, forwardRef } from 'react';
import { downloadFileAsBinary } from '../App';
import { checkUserAuthorization } from '../utils';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import MaterialTable, { MTableToolbar } from 'material-table'
import { TablePagination } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ShareIcon from '@material-ui/icons/Share';
import GetAppIcon from '@material-ui/icons/GetApp';
import LinearProgress from '@material-ui/core/LinearProgress';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useDocuments, useFetchImageFromVideoServer, useTags } from '../customEffects.js';
import DateTimeRange from './DateTimeRange.js';
import DocumentsTableToolbar from './DocumentsTableToolbar';
import TagsArray from './TagsArray.js';
import SelectUsergroups from './SelectUsergroups.js';
import axios from 'axios';
import moment from 'moment';
import { FormatCell } from './showFormat';
import CreateDocumentDlg from '../CreateDocumentDlg.js';



// This patch should be removed, when the issue is fixed.
function PatchedPagination(props) {
  const {
    ActionsComponent,
    onChangePage,
    onChangeRowsPerPage,
    ...tablePaginationProps
  } = props;

  return (
    <TablePagination
      {...tablePaginationProps}
      // @ts-expect-error onChangePage was renamed to onPageChange
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      ActionsComponent={(subprops) => {
        const { onPageChange, ...actionsComponentProps } = subprops;
        return (
          // @ts-expect-error ActionsComponent is provided by material-table
          <ActionsComponent
            {...actionsComponentProps}
            onChangePage={onPageChange}
          />
        );
      }}
    />
  );
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#4caf50',
    },
    secondary: {
      main: '#47A3E8',
    },
  }
});


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh', // Takes the full viewport height
  },
  tableContainer: {
    flexGrow: 1, // Allows the table to take up remaining space
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden', // Prevents unwanted scrolling
  },
  documentsTable: {
    flexGrow: 1, // Ensures the table expands
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(0.5),
  },
  mediaRootThumbnail: {
    width: 160,
  },
  mediaRootInfo: {
    width: 260,
  },
  media: {
    height: 120,
  },
  badge: {
    paddingTop: 10,
    paddingRight: 24
  },
  tagsFilter: {
    display: 'flex',
    color: 'blue',
    padding: '0px 10px'
  },
  countryFilter: {
    display: 'flex',
    color: 'blue',
    padding: '0px 10px'
  },
  usergroupFilter: {
    display: 'flex',
    color: 'blue',
    padding: '0px 10px'
  },
}));


const tableIcons = {
  AddIcon: forwardRef((props, ref) => <AddIcon {...props} ref={ref} style={{ color: 'blue' }} />),
  DeleteIcon: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  ExportList: forwardRef((props, ref) => <GetAppIcon {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Share: forwardRef((props, ref) => <ShareIcon {...props} ref={ref} />),
};

const DocumentPopover = props => {
  const classes = useStyles();
  const { server, thumbnail, serverToken, update } = props;
  const { image } = useFetchImageFromVideoServer(`${server.serverHost}/documents/${thumbnail}`, serverToken, update);

  return (

    image ?
      <Card className={classes.mediaRootThumbnail}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={image}
          />
        </CardActionArea>
      </Card> : null
  );
}



/**
 * DocumentsTable
 * @param {*} props 
 * @returns 
 */
export default function DocumentsTable(props) {
  const token = props.server.token ? props.server.token : Buffer.from(`${props.server.username || 'guest'}:${props.server.password || 'guest'}`, 'utf8').toString('base64');
  const [documentsFilter, setDocumentFilter] = useState(props.documentsFilter ? { ...props.documentsFilter } : {});
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowEditEnabled, setRowEditEnabled] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [thumbnail, setThumbnail] = useState({});
  const [toolBarCtrl, setToolBarCtrl] = useState({});
  const [selectedTimes, setSelectedTimes] = useState({ start: documentsFilter.start, end: documentsFilter.end });
  const [selectedTagsArr, setSelectedTagsArr] = useState(props.documentsFilter && props.documentsFilter.tags ? props.documentsFilter.tags.split(',') : []);
  const { tags } = useTags(props.server.serverHost, token);
  const [selectedUserGroupsArr, setSelectedUserGroupsArr] = useState(props.documentsFilter && props.documentsFilter.usergroups ? props.documentsFilter.usergroups.split(',') : []);
  const { isLoading, hasError, documents } = useDocuments(props.server.serverHost, token, documentsFilter, props.documentsUpdateTime);
  const [isQuerying, setQuerying] = useState(false);
  const [createDocumentDlgOpen, setCreateDocumentDlgOpen] = useState({ open: false, mode: 'create' });

  const actions = [];

  actions.push({
    icon: tableIcons.AddIcon,
    tooltip: 'Add Document',
    isFreeAction: true,
    onClick: event => {
      setCreateDocumentDlgOpen({ open: true, mode: 'create' });
    }
  });
  actions.push({
    icon: tableIcons.Edit,
    tooltip: 'Edit Document',
    isFreeAction: true,
    disabled: !selectedRow || !rowEditEnabled,
    onClick: event => {
      if (selectedRow) {
        setCreateDocumentDlgOpen({ open: true, mode: 'edit' });
      }
    }
  });

  actions.push({
    icon: tableIcons.DeleteIcon,
    tooltip: 'Delete Document(s)',
    isFreeAction: true,
    disabled: !selectedRow || !rowEditEnabled,
    onClick: () => {
      if (selectedRow) {
        handleDeleteDocument(selectedRow);
      } else {
        console.warn('No row selected for deletion.');
      }
    }
  });
  actions.push({
    icon: tableIcons.Export,
    tooltip: 'Download Document',
    isFreeAction: true,
    disabled: !selectedRow,
    onClick: (event, data) => {

      try {
        downloadFileAsBinary(`${props.server.serverHost}/documents/${selectedRow.fileName}`, selectedRow.accessToken)
          .then(blob => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = selectedRow.fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url);
          })
          .catch(err => {
            console.error('Download failed:', err);
            if (props.onError) props.onError(`Failed to download document: ${err.message}`);
          });

      } catch (error) {
        if (props.onError) props.onError(`Failed to download document: ${error.message}`);
      }
    }
  });



  useEffect(() => {

    if (props.onLoading)
      props.onLoading(isLoading);

  }, [isLoading]);

  useEffect(() => {

    setDocumentFilter({ ...props.documentsFilter });
  }, [props.documentsFilter]);

  useEffect(() => {

    if (props.onError && hasError.error)
      props.onError(hasError.message);

  }, [hasError]);


  const handleSelectedRow = (evt, selectedRow) => {

    setSelectedRow(selectedRow);
    const enabled = checkUserAuthorization(props.server, selectedRow);
    setRowEditEnabled(enabled);
    props.onDocumentClick && props.onDocumentClick(selectedRow) 
  }

  const handlePopoverOpen = (event) => {

    if (!event.currentTarget.attributes.thumbnail?.value) {
      setAnchorEl(null);
      return;
    }

    try {
      setThumbnail({
        url: event.currentTarget.attributes.thumbnail.value,
        accessToken: event.currentTarget.attributes.accessToken?.value
      });

      setAnchorEl(event.currentTarget);
    } catch (error) {
      console.error('Error setting popover anchor:', error);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };


  const handleDeleteDocument = async data => {
    try {
      const response = await axios.delete(`${props.server.serverHost}/api/documents/${data._id}`, {
        headers: { 'Authorization': `Basic ${token}` }
      });
      if (response.status === 200) {
        props.onDeleteDocument && props.onDeleteDocument(data);
        setSelectedRow(null);
        props.onUpdateDocuments && props.onUpdateDocuments(); // Trigger a refresh of the documents     
      } else {
        const errorMessage = response.data?.message || `Unexpected response status: ${response.status}`;
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error('Error deleting document:', error);
      const errorDetails = error.response?.data || error.message;
      if (props.onError) props.onError(`Failed to delete document. - ${JSON.stringify(errorDetails)}`);
    }
  }

  const open = Boolean(anchorEl);

  const columns = [
    {
      title: 'Type', field: 'documentType', width: '5%', filtering: true,
      render: rowData =>
        <div title={rowData.title} thumbnail={rowData.thumbnail}
          accessToken={rowData.accessToken}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}>
          <FormatCell state={rowData.state} serverUrl={props.server.serverHost} documentType={rowData.documentType} update={rowData.update} />
          {rowData?.thumbnail ?
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <DocumentPopover
                server={props.server}
                thumbnail={thumbnail.url}
                serverToken={token}
                update={rowData.update}
              />
            </Popover> : null
          }
        </div>
    },
    {
      title: 'Title', field: 'title', width: '15%', render: rowData =>
        <Tooltip title={`Title`}>
          <strong>{`${rowData.title || 'N/A'}`}</strong>
        </Tooltip>
    },
    {
      title: 'Description', field: 'description', render: rowData => `${rowData.description || 'N/A'}`
    },
    { title: 'Platform', field: 'platformName', width: '10%', hidden: window.innerWidth < 960, render: rowData => `${rowData.platformName || 'N/A'}` },
    {
      title: 'Locked', field: 'restricted', width: '4%',
      render: rowData => rowData.restricted ?
        <Tooltip title="Restricted">
          <LockOutlinedIcon style={{ fontSize: '18px', color: 'grey' }} />
        </Tooltip>
        : null
    },
    { title: 'Author', field: 'author', width: '10%', render: rowData => `${rowData.author || 'N/A'}` },
    {
      title: 'Created', field: 'creationTime', width: '10%', hidden: window.innerWidth < 960,
      render: rowData => { return (rowData.creationTime !== undefined && rowData.creationTime !== null ? moment(rowData.creationTime).format("MMM DD YYYY, h:mm") : 'N/A') }
    }
  ];



  const applyQueryFilter = async filter => {

    setQuerying(true);

    setDocumentFilter({ ...filter });

    if (props.onApplyQueryFilter) {

      if (filter) {
        if (filter.hasOwnProperty('start') && filter.start === undefined) {
          delete filter.start;
        }
        if (filter.hasOwnProperty('end') && filter.end === undefined) {
          delete filter.end;
        }
        if (filter.hasOwnProperty('tags') && (filter.tags === undefined || filter.tags.length === 0)) {
          delete filter.tags;
        }
        if (filter.hasOwnProperty('usergroups') && (filter.usergroups === undefined || filter.usergroups.length === 0)) {
          delete filter.usergroups;
        }
      }
      else {
        filter = {};
      }

      props.onApplyQueryFilter(filter);
    }

    setQuerying(false);
  }

  const handleToolbarSelection = selection => {

    const newState = { ...toolBarCtrl };
    newState[selection] = !toolBarCtrl[selection];
    setToolBarCtrl(newState);
    let filter;

    switch (selection) {

      case 'time':

        if (!newState[selection]) {
          filter = { ...documentsFilter, start: undefined, end: undefined };
        }
        else {
          const timeSelection = localStorage.getItem('timeSelection');
          if (timeSelection) {
            const t = JSON.parse(timeSelection);
            filter = { ...documentsFilter, start: t.start, end: t.end };
          }
        }

        applyQueryFilter(filter);
        break;
      case 'geo':
        if (!newState[selection]) {
          filter = { ...documentsFilter, aoiMode: undefined, geometry: undefined };
          applyQueryFilter(filter);
        }
        else {
          filter = documentsFilter;
        }

        if (props.onSelectedFilter)
          props.onSelectedFilter(selection, newState[selection], { aoiMode: (filter && filter.aoiMode) ? filter.aoiMode : (props.documentsFilter && props.documentsFilter.aoiMode ? props.documentsFilter.aoiMode : 'frameCenter') });
        break;

      case 'tags':

        if (newState[selection]) {
          filter = { ...documentsFilter, tags: selectedTagsArr.join(',') };
        }
        else {
          filter = { ...documentsFilter, tags: undefined };
        }

        applyQueryFilter(filter);

        if (props.onSelectedFilter)
          props.onSelectedFilter(selection, newState[selection]);
        break;


      case 'usergroups':

        if (newState[selection]) {
          filter = { ...documentsFilter, usergroups: selectedUserGroupsArr.join(',') };
        }
        else {
          filter = { ...documentsFilter, usergroups: undefined };
        }

        applyQueryFilter(filter);

        if (props.onSelectedFilter)
          props.onSelectedFilter(selection, newState[selection]);
        break;

      default:
        break;
    }

    if (props.onToolbarSelectionChanged)
      props.onToolbarSelectionChanged(newState);
  }

  const handleSelectTime = async selectedTimes => {

    let filter = { ...documentsFilter, start: selectedTimes.start, end: selectedTimes.end };

    setSelectedTimes(selectedTimes);
    localStorage.setItem('timeSelection', JSON.stringify(selectedTimes));

    applyQueryFilter(filter);
  }

  const handleTagsChange = async selectedTags => {

    const tagsFilterStr = selectedTags.join(',');
    const filter = { ...documentsFilter, tags: tagsFilterStr };

    setSelectedTagsArr(selectedTags);
    applyQueryFilter(filter);
  }

  const handleUserGroupsChange = ugs => {

    const usergroupFilterStr = ugs.join(',');
    const filter = { ...documentsFilter, usergroups: usergroupFilterStr };

    setSelectedUserGroupsArr(ugs);

    applyQueryFilter(filter);
  }

  const handleDocumentClose = () => {
    setCreateDocumentDlgOpen({ open: false });
    setSelectedRow(null);
  }

  const classes = useStyles({ ...props });

  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={theme}>
        <MaterialTable className={classes.documentsTable}
          data-testid="table"
          icons={tableIcons}
          columns={columns}
          data={documents}
          title={<DocumentsTableToolbar documents={documents} controls={toolBarCtrl} documentsFilter={documentsFilter} onSelection={handleToolbarSelection} />}
          onRowClick={handleSelectedRow}
          options={{
            sorting: true,
            cellStyle: { padding: '0.4em' },
            headerStyle: { padding: '0.4em' },
            selectionProps: rowData => ({
              //    disabled: rowData.parentId !== undefined,
              color: 'secondary'
            }),
            rowStyle: rowData => ({
              backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#EEE' : '#FFF'
            }),
            filtering: toolBarCtrl.filter
          }}
          actions={actions}
          components={{
            Pagination: PatchedPagination,
            Toolbar: pr => (
              <div>
                <MTableToolbar {...pr} />
                {
                  toolBarCtrl.time &&
                  <div style={{ padding: '0px 10px' }}>
                    <DateTimeRange selectTime={selectedTimes} onTimeSelected={handleSelectTime} />
                  </div>
                }
                {
                  toolBarCtrl.tags &&
                  <div className={classes.tagsFilter}>
                    <Typography noWrap variant="overline" display="block" style={{ marginRight: '20px' }} gutterBottom>Select tags</Typography>
                    <TagsArray tags={tags.map(tag => tag.title)} selectedTags={selectedTagsArr} onChange={(event, newValue) => handleTagsChange(newValue)} />
                  </div>
                }
                {
                  toolBarCtrl.usergroups &&
                  <div className={classes.usergroupFilter}>
                    <Typography noWrap variant="overline" display="block" gutterBottom>Select usergroups</Typography>
                    <SelectUsergroups serverUrl={props.server.serverHost} token={token} selectedUserGroups={selectedUserGroupsArr} onUserGroupsChange={handleUserGroupsChange} />
                  </div>
                }
              </div>
            )
          }}
        />
        {createDocumentDlgOpen.open && <CreateDocumentDlg mode={createDocumentDlgOpen.mode} document={selectedRow} onClose={handleDocumentClose} />}
        {isLoading || isQuerying && <LinearProgress />}
      </MuiThemeProvider>
    </div>
  );
}

DocumentsTable.propTypes = {
  server: PropTypes.object.isRequired,
  documentsFilter: PropTypes.object,
  documentsUpdateTime: PropTypes.object,
  onLoading: PropTypes.func,
  onError: PropTypes.func,
  onDocumentClick: PropTypes.func,
  onApplyQueryFilter: PropTypes.func,
  onUpdateDocuments: PropTypes.func,
  onDeleteDocument: PropTypes.func,
  onShare: PropTypes.func,
  onSelectedFilter: PropTypes.func,
};

