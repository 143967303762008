export const securityType = [
    {
      value: 'UNCLASSIFIED',
      label: 'UNCLASSIFIED',
    },
    {
      value: 'RESTRICTED',
      label: 'RESTRICTED',
    },
    {
      value: 'CONFIDENTIAL',
      label: 'CONFIDENTIAL',
    },
    {
      value: 'SECRET',
      label: 'SECRET',
    },
    {
      value: 'TOP SECRET',
      label: 'TOP SECRET',
    }
];

export const documentType = [
    {
      value: 'Note',
      label: 'Note',
    },
    {
      value: 'Report',
      label: 'Report',
    },
    {
      value: 'Document',
      label: 'Document',
    },
    {
      value: 'Image',
      label: 'Image',
    },
    {
      value: 'Video',
      label: 'Video',
    },
    {
      value: 'Log',
      label: 'Log',
    }
];
