import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SnapshotForm } from 'stserver-frontend-comp';
import InputLabel from '@material-ui/core/InputLabel';
import GetAppIcon from '@material-ui/icons/GetApp';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SelectUsergroups from './Components/SelectUsergroups'
import { serverContext } from './App';
import { useTags } from './customEffects';
import { captureCurrentFrame } from './utils';


const useStyles = makeStyles(theme => ({

  formControl: {
    minWidth: 280,
  },
  formControlRestricted: {
    marginTop: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

}));


const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" {...props} ref={ref} />;
});


/**
 * CreateSnapshotDlg renders a dialog that wraps SnapshotForm.
 * It uses a ref to trigger form submission when Ok is pressed.
 * The Ok button is disabled until the form is valid.
 */
function CreateSnapshotDlg(props) {
  const { server } = useContext(serverContext);
  const [curSnapshot, setCurSnapshot] = useState({ tags: [] });
  const [snapshotDataUrl, setSnapshotDataUrl] = useState();
  const [formIsValid, setFormIsValid] = useState(false);
  const [selectedUserGroups, setSelectedUserGroups] = useState([]);
  const { tags } = useTags(server?.serverHost, server?.token);


  const theme = useTheme();
  const classes = useStyles();

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Create a ref for SnapshotForm
  const snapshotFormRef = useRef(null);

  useEffect(() => {
    if (props.player) {
      // Capture the snapshot from the player as a data URL
      let tm;
      if (props.player.getSnapshot) {
        tm = props.player.getSnapshot().toDataURL('image/jpeg', 0.8);
      } else {
        tm = captureCurrentFrame(props.player, 1).toDataURL('image/jpeg', 0.8);
      }

      setSnapshotDataUrl(tm);
      setCurSnapshot({
        thumbnailUrl: tm,
        restricted: false,
        startTime: props.timestamp
      });
    }
  }, []);

  // Callback to receive form validation state from SnapshotForm
  const handleValidationChange = (isValid) => {
    setFormIsValid(isValid);
  };

  // Trigger form submission when Ok is pressed.
  const handleCreateSnapshot = () => {
    if (snapshotFormRef.current) {
      snapshotFormRef.current
        .submit()
        .then((values) => {
          // Only call onCreateSnapshot if the form is valid.
          const usergroupsIds = selectedUserGroups.map(g => g._id)
          if (formIsValid) {
            props.onCreateSnapshot && props.onCreateSnapshot({
              snapshotDataUrl: snapshotDataUrl,
              usergroups: usergroupsIds,
              platformName: props.player.platformName,
              sensorName: props.player.sensorName,
              missionName: props.player.missionIdOrName,
              restricted: curSnapshot.restricted,
              ...values
            });
          }
        })
        .catch((err) => {
          console.error('Submission error', err);
        });
    }
  };

  const handleUserGroupsChange = ugs => {
    setSelectedUserGroups(ugs);
  }

  const handleRestrictedChange = (event) => {
    setCurSnapshot({
      ...curSnapshot,
      restricted: event.target.checked
    });
  }

  const handleDownloadSnapshot = () => {
    if (snapshotDataUrl) {

      let snapshotName = 'snapshot.jpg'
      try {
        const timestamp = curSnapshot.startTime ? new Date(curSnapshot.startTime) : new Date();
        const name = curSnapshot.title || 'snapshot';
        const formattedTimestamp = `${timestamp.getFullYear()}-${String(timestamp.getMonth() + 1).padStart(2, '0')}-${String(timestamp.getDate()).padStart(2, '0')}-${String(timestamp.getHours()).padStart(2, '0')}:${String(timestamp.getMinutes()).padStart(2, '0')}:${String(timestamp.getSeconds()).padStart(2, '0')}`;
        snapshotName = `${formattedTimestamp}-${server.username}_${name.replace(/\s+/g, '_')}.jpg`;

      } catch (error) {

      }
      const link = document.createElement('a');
      link.href = snapshotDataUrl;
      link.download = snapshotName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      onClose={props.onClose}
      fullWidth={true}
      fullScreen={fullScreen}
    >
      <DialogTitle>📸 Create Snapshot</DialogTitle>
      <DialogContent>
        <div style={{ textAlign: "left" }}>
          <SnapshotForm
            ref={snapshotFormRef}
            snapshot={curSnapshot}
            onSnapshotUpdate={(values) => setCurSnapshot(values)}
            onValidationChange={handleValidationChange}
            editMode={props.editMode}
            availableTags={tags.map(tag => tag.title)}
            theme={props.theme}
          />
          <div style={{ display: "flex", width: "100%", gap: "16px" }}>
            <FormControl className={classes.formControl} style={{ flex: 7 }}>
              <InputLabel>User groups</InputLabel>
              <SelectUsergroups canDeleteDemo selectedUserGroups={selectedUserGroups} onUserGroupsChange={handleUserGroupsChange} />
            </FormControl>
            <FormControl className={classes.formControlRestricted} style={{ flex: 3 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={curSnapshot.restricted}
                    onChange={handleRestrictedChange}
                    name="restricted"
                    color="primary"
                  />
                }
                disabled={server.userRole !== 'superAdmin' && server.userRole !== 'admin' && server.userRole !== 'groupAdmin'}
                label="Restricted"
                labelPlacement="start"
              />
            </FormControl>
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Tooltip title='Download snapshot'>
          <IconButton aria-label="download" onClick={handleDownloadSnapshot}  >
            <GetAppIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <div>
          <Button onClick={props.onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreateSnapshot} color="primary" disabled={!formIsValid}>
            Ok
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default CreateSnapshotDlg;
