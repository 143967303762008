import React, { useState, useEffect, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { serverContext } from '../App';
import axios from 'axios';

const PlatformTypeSelect = props => {
    const { server } = useContext(serverContext);
    const [platformTypes, setPlatformTypes] = useState([]);

    useEffect(() => {

        (async () => {
            try {
                const res = await axios(
                    `${server.serverHost}/api/platformTypes`, {
                    headers: { 'Authorization': `Basic ${server.token}` }
                });

                setPlatformTypes(res.data);

            } catch (error) {
                console.log(error.message);
            }

        })();
    }, [server.serverHost, server.token]);

    return (
        <TextField
            id="select-platformType"
            select
            label="Platform type"
            value={props.platformType}
            onChange={(event) => props.onChange(event.target.value)}
        >
            {platformTypes?.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
}

export default PlatformTypeSelect;