import React, { useState, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { serverContext } from '../App';
import ColorPicker from './colorPicker';
import Grid from '@material-ui/core/Grid';
import { newMissionContext } from '../CreateMissionDlg';
import PlatformTypeSelect from './PlatformTypeSelect'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 240,
    }
  },
  colorCtrl: {
    margin: theme.spacing(1),
    flexGrow: 1,  
  },
  colorGrid: {
    marginTop: 18,  
  },
  colorPicker: {
    marginLeft: 20
  },
  formControl: {
    margin: theme.spacing(2),
    width: 240,
  }
}));

const filter = createFilterOptions();


const PlatformOption = props => {
  const [value, setValue] = useState(props.name);

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (newValue && newValue.inputValue) {
          setValue({
            name: newValue.inputValue,
          });         
        }

        setValue(newValue);
        if (props.onChange && newValue != null)
          props.onChange(newValue.inputValue);
      }}

      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            name: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}

      id="platform-name"
      options={props.platforms}
      getOptionLabel={option => {

        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.name;
      }}
      renderOption={option => option.name}
      style={{ width: 300 }}
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderInput={params => (
        <TextField {...params} label="Platform name" />
      )}
    />
  );
}



export default function MissionPresentationForm() {

  const { server } = useContext(serverContext);
  const { newMission, dispatchNewMission } = useContext(newMissionContext);
  const classes = useStyles();

  const handlePlatformNameChange = name => {
    dispatchNewMission({ type: 'SET_PLATFORM_NAME', payload: name });
  };

  const handlePlatformTypeChange = newPlatformType => {
    dispatchNewMission({ type: 'SET_PLATFORM_TYPE', payload: newPlatformType });
  };

  const handleSortOrderChange = e => {
    dispatchNewMission({ type: 'SET_MISSION_SORT_ORDER', payload: e.target.value });
  };

  const handleColorChange = color => {
    dispatchNewMission({ type: 'SET_PLATFORM_COLOR', payload: color });
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Grid container >
        <Grid item xs={6}>
          <PlatformOption name={newMission.platform.name} platforms={server.platforms ? server.platforms : []} onChange={handlePlatformNameChange} />
        </Grid>
        <Grid item xs={6} className={classes.colorGrid} >
          <FormControl className={classes.colorCtrl}>
           <FormControlLabel
              control={<ColorPicker className={classes.colorPicker} color={newMission.platform.color} onChange={handleColorChange} />}
              labelPlacement="start"
              label="Color"
            />            
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <PlatformTypeSelect platformType={newMission.platform?.type} onChange={handlePlatformTypeChange} />
        </Grid>    
    
        <Grid item xs={6}>
          <TextField
            id="sortOrder"          
            label="Sort order"
            type="number"
            value={newMission.sortOrder}
            onChange={handleSortOrderChange}
          >
            
          </TextField>
        </Grid>      
      </Grid>
    </form>
  );
}
