import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Hidden from '@material-ui/core/Hidden';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlatformTable from './Components/PlatformTable';
import MissionPlayerGrid from './Components/MissionPlayerGrid';
import MissionPlaybackToolbar from './Components/MissionPlaybackToolbar';
import { MissionTable } from 'stserver-frontend-comp';
import { MapControlsMode } from './Map/UavMap';
import { appContext, missionsContext, serverContext, translateMissions, getFromServer } from './App';
import queryString from 'query-string';

const useStyles = makeStyles(theme => ({
    toolboxHeader: {
        flexGrow: 1,
    },
    root: {

        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        // '& .MuiPaper-root': {
        //     boxShadow: 'none'
        // },
        '& .MuiAccordion-root.Mui-expanded': {
            paddingBottom: 2,
            paddingTop: 0,
            marginBottom: 4
        },

    },
    // timeline: {
    //     marginTop: theme.spacing(0.5),
    // }
}));


/**
 * Missions
 * 
 * @param  {} props
 */
export default function Missions(props) {
    const classes = useStyles();
    const { server, dispatchServer } = useContext(serverContext);
    const { dispatchMissions } = useContext(missionsContext);
    const { dispatchApp } = useContext(appContext);
    const [activeSensors, setActiveSensors] = useState([]);
    const [selectedMission, setSelectedMission] = useState(null);
    const [activeLiveSensors, setActiveLiveSensors] = useState([]);
    const [showSensorToolbar, setShowSensorToolbar] = useState(true);
    const [selectedControls, setSelectedControls] = useState([]);   // Map, KlvView etc
    const [selectMapControl, setSelectMapControl] = useState(undefined);
    const [queryParams, setQueryParams] = useState({});
    const [missionUpdateTime, setMissionUpdateTime] = useState(null);
    const [aoiControlsMode, setAoiControlsMode] = useState();
    const [takeSnapshot, setTakeSnapshot] = useState(false);


    const timelineOpen = selectedControls && selectedControls.includes('timeline');


    useEffect(() => {

        try {

            if (props.mode === 'live')
                dispatchServer({ type: 'LIVE_STREAM_EVENTS', payload: 'SUBSCRIBE' });
        } catch (error) {
            console.log(error.message);
        }


        return () => {
            // Clean up playing sensors 
            setActiveSensors([]);
            setActiveLiveSensors([]);
            if (props.mode === 'live')
                dispatchServer({ type: 'LIVE_STREAM_EVENTS', payload: 'UNSUBSCRIBE' });
        };
    }, [props.mode, dispatchServer])

    useEffect(() => {

        let queryParams = {};
        if (queryString && props.search.length > 0)
            queryParams = queryString.parse(props.search);

        setQueryParams(queryParams);
    }, [props.search])


    const handleSensorsExpanded = expanded => {
        setShowSensorToolbar(expanded);
    }

    const handleSelectedSensors = sensors => {
        setActiveSensors(sensors);
    }

    const handleSelectedMission = mission => {

        setSelectedMission(mission);
        //   setSelectedMission(mission[0].mName);
        // FIXME:  Patch to properly update timeline
        setTimeout(() => {
            setMissionUpdateTime(Date.now());
        }, 500);
    }

    const handleSelectedPlatform = (platform, sensors) => {

    }

    const handleSelectedLiveSensors = liveSensors => {
        setActiveLiveSensors(liveSensors);
    }

    const handleControlSelectionChange = controls => {
        setSelectedControls(controls);
    }

    // Called when Mission Controls Cfg is Loaded - klv, map, etc
    const handleMissionControlsCfgLoaded = controls => {
        setSelectedControls(controls);
    }

    const handleSelectedFilter = (selection, state, params) => {

        switch (selection) {

            case 'geo':
                setSelectMapControl(state ? MapControlsMode.FilterMissions : MapControlsMode.None);
                if (params && params.hasOwnProperty('aoiMode')) {
                    setAoiControlsMode(params.aoiMode);
                    if (state)
                        dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Please select on a map the "Filter Missions" mode with the required (${params.aoiMode ? params.aoiMode.toUpperCase() : '-'}) AOI mode`, severity: 'info' } });
                }

                break;

            default:
                break;
        }
    }

    const handleSnapshot = () => {
        setTakeSnapshot(true);
    }

    const handleOnSnapshot = () => {
        setTakeSnapshot(false);
    }

    const handleApplyQueryFilter = async filter => {
        try {
            if (filter)
                sessionStorage.setItem('queryFilter', JSON.stringify(filter));
            else
                sessionStorage.removeItem("queryFilter");

            setAoiControlsMode(filter.aoiMode);

            // if(filter.aoiMode)
            //   dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Please select on a map the "Filter Missions" mode with the required (${ filter.aoiMode.toUpperCase()}) AOI mode`, severity: 'info' } });


        } catch (error) {
        }

        setQueryParams(filter);

        //FIXME: These are not really needed. Have to remove them
        dispatchApp({ type: 'UPDATE_MISSIONS_FILTER', payload: filter });
        const resMissionList = await getFromServer(`missions/brief`, filter, server);
        dispatchMissions({ type: 'SET_MISSIONS', payload: translateMissions(resMissionList.data) });
    }

    const handleShareUrl = url => {
        try {
            window.open(`mailto:?subject=StServer%20video%20link&body=${url}`);
        } catch (error) {

        }
    }


    return (
        <div className={classes.root} style={{ 'paddingLeft': '0px' }}>
            <Accordion defaultExpanded TransitionProps={{ unmountOnExit: false }} onChange={(event, expanded) => handleSensorsExpanded(expanded)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    aria-label="Expand"
                    id="panel1a-header"
                >
                    <Grid container className={classes.toolboxHeader}
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                    >
                        <Hidden only={['xs', 'sm']}>
                            <Grid item xs={1} md={2} lg={2}>
                                <Tooltip title="Total sensors in the mission">
                                    <Badge badgeContent={activeSensors.length} color="primary">
                                        <Typography noWrap variant="overline" display="block">Sensors</Typography>
                                    </Badge>
                                </Tooltip>
                            </Grid>
                        </Hidden>
                        {showSensorToolbar &&
                            <Grid item xs={10} md={10} lg={10} >
                                <MissionPlaybackToolbar mode={props.mode} onControlSelectionChange={handleControlSelectionChange} onMissionControlsCfgLoaded={handleMissionControlsCfgLoaded} snapshotEnabled={true} onSnapshot={handleSnapshot} />
                            </Grid>
                        }
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <MissionPlayerGrid mode={props.mode} useLiveLowLatency={server.useLiveLowLatency} useWebRtcVideo={server.useWebRtcVideo} webRtcService={server.webRtcService} useLiveRtspHls={server.useLiveRtspHls} mission={selectedMission} sensors={props.mode === 'vod' ? activeSensors : activeLiveSensors} controls={selectedControls} selectMapControl={selectMapControl} onApplyQueryFilter={handleApplyQueryFilter} timelineOpen={timelineOpen} missionUpdateTime={missionUpdateTime} aoiControlsMode={aoiControlsMode} takeSnapshot={takeSnapshot} onSnapshot={handleOnSnapshot} onSnapshotClose={() => setTakeSnapshot(false)} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            {
                props.mode === 'live' ?

                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} lg={12}>
                            <PlatformTable title=' ' onSelectPlatform={handleSelectedPlatform} onSelectedLiveSensors={handleSelectedLiveSensors} />
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} lg={12}>
                            <MissionTable serverUrl={server.serverHost} serverToken={server.token} missionsFilter={queryParams} onMissionClick={handleSelectedMission} onSelectedSensors={handleSelectedSensors} onSelectedFilter={handleSelectedFilter} onApplyQueryFilter={handleApplyQueryFilter} onShare={handleShareUrl} />
                        </Grid>
                    </Grid>
            }           

        </div>

    );
}