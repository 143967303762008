import React, { useContext, useState, useMemo, useCallback, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import { serverContext, appContext, fetchFromServer, uploadToServer } from './App';
import Chip from '@material-ui/core/Chip';
import CheckedIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useDropzone } from 'react-dropzone';
import { StJwtLicenseInfo } from 'stserver-frontend-comp';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};



const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" {...props} ref={ref} />;
})

const LicenseChip = props => {
    return (
        <Chip color='primary' style={(props.licenseInfo.valid) ? { backgroundColor: '#4caf50' } : { backgroundColor: '#f72500' }} icon={props.licenseInfo.valid ? <CheckedIcon /> : <ErrorIcon />} label={props.licenseInfo.message} variant="outlined" />
    )
}


const DropzoneLicense = props => {

    const [licenseFiles] = useState([]);

    const onDrop = useCallback(acceptedFiles => {

        if (acceptedFiles && props.onFileDrop)
            props.onFileDrop(acceptedFiles);

    }, [props])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles
    } = useDropzone({ onDrop, multiple: true });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
        ...(acceptedFiles ? acceptedFiles : {}),
    }), [isDragActive, isDragReject, isDragAccept, acceptedFiles ]);

    return (
        <div className="container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop a license file (.lic) here, or click to select it</p>
            </div>
            <aside>
                <ul>{licenseFiles}</ul>
            </aside>
        </div>
    )
}



function LicenseDlg(props) {
    const { server, dispatchServer } = useContext(serverContext);
    const { dispatchApp } = useContext(appContext);
    const [licenseInfo, setLicenseInfo] = useState(props.licenseInfo);
    const [value, setValue] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await fetchFromServer('licenseInfo', server);
                setLicenseInfo(res.data);
            } catch (error) {
                setLicenseInfo(null);
            }
        };

        if (server.serverHost)
            fetchData();
    }, [server]);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleLicFileDrop = async files => {

        try {
            const res = await uploadToServer(`upload/license`, 'license', files, server);

            const validation = res.data;
            if (validation.valid)
                dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `License uploaded successfully.` } });
            else
                dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Uploaded License is invalid`, severity: 'warning' } });

            const info = await fetchFromServer('licenseInfo', server);
            setLicenseInfo(info.data);

            dispatchServer({ type: 'UPDATE_LICENSE_VALID', payload: info.data });

        } catch (error) {

            dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `License upload error - ${error.response ? error.response.data : error.message}`, severity: 'error' } });
        }
    }


    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => props.onClose()}
        >
            <DialogTitle id="license-dialog-slide-title">
                {"🧾 License"}
            </DialogTitle>
            <DialogContent>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="NodeInfo" />
                    <Tab label="Upload" />
                    <Tab label="Options" />
                </Tabs>
                <TabPanel value={value} index={0} >
                    <TextField
                        id="nodeinfo"
                        margin='normal'
                        defaultValue={server.nodeInfo}
                        value={server.nodeInfo}
                        fullWidth
                        InputProps={{
                            readOnly: true,
                            style: { fontSize: 14 }
                        }}
                    />
                    {
                        licenseInfo && <LicenseChip licenseInfo={licenseInfo} />
                    }
                </TabPanel>
                <TabPanel value={value} index={1} >
                    <DropzoneLicense onFileDrop={handleLicFileDrop} />
                </TabPanel>
                <TabPanel value={value} index={2} >
                    <StJwtLicenseInfo serverUrl={server.serverHost} username={server.username} password={server.password} />
                </TabPanel>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => props.onClose()} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default LicenseDlg;