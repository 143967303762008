import React, { useState, useContext, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Alert from '@material-ui/lab/Alert';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import PersonIcon from '@material-ui/icons/Person';
import Chip from '@material-ui/core/Chip';
import SettingsIcon from '@material-ui/icons/Settings';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SelectUsergroups from './Components/SelectUsergroups';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useServerGet } from './customEffects';
import { serverContext, appContext, postToServer, callServer } from './App';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  buttonCreate: {
    marginBottom: -14,
    marginRight: 4
  },
  buttonSettings: {
    marginBottom: -20,
    marginRight: 4,
    border: 'none',
  },
  circularProgress: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  progress: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const getRoomColor = (room) => {
  const metadata = room.metadata ? JSON.parse(room.metadata) : null;

  if (metadata && metadata.usergroups) {
    const usergroups = metadata.usergroups;

    if (Array.isArray(usergroups) && usergroups.length > 0) {
      if (usergroups.includes('Demo'))
        return 'primary';
      else
        return 'secondary';
    }
  }
  return 'primary';
}

export default function CreateConfDlg(props, ref) {

  const classes = useStyles();
  const { server } = useContext(serverContext);
  const { dispatchApp } = useContext(appContext);
  const [selectedUserGroups, setSelectedUserGroup] = useState([]);
  const [roomName, setRoomName] = useState('');
  const [emptyTimeout, setEmptyTimeout] = useState(60);
  const [maxParticipants, setMaxParticipants] = useState(10);
  const [userRoleAdmin, setUserRoleAdmin] = useState(false);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [createRoomButtonEnabled, setCreateRoomButtonEnabled] = useState(true);
  const [createRoomButtonError, setCreateRoomButtonError] = useState();
  const [updated, setUpdated] = useState(Date.now());
  const [isCreating, setIsCreating] = useState(false);
  const [isConfirmDlgOpen, setIsConfirmDlgOpen] = useState(false);
  const [actionInfo, setActionInfo] = useState({}); // Action confirmation data will be stored here
  const [confirmDlgMessage, setConfirmDlgMessage] = useState('');
  const { data: rooms, hasError, isLoading } = useServerGet('conference/rooms', server, updated);



  useEffect(() => {

    setUserRoleAdmin(server && (server.userRole === 'superAdmin' || server.userRole === 'admin'));

  }, [server, server.userRole])

  useEffect(() => {

    if (roomName || (roomName.length > 0 && rooms && Array.isArray(rooms))) {
      if (rooms.find(room => room.name === roomName)) {
        setCreateRoomButtonError(`Room ${roomName} already exists`);
      }
      else {
        setCreateRoomButtonError(undefined);
        setCreateRoomButtonEnabled(true);
        return;
      }
    }

    setCreateRoomButtonEnabled(false);

  }, [rooms, roomName])

  // useEffect(() => {
  //   if(!hasError) {
  //     setErrorMsg(hasError);
  //   }
  // }, [hasError])


  const handleRoomNameChange = event => {
    const roomName = event.target.value;
    setRoomName(roomName);
  };

  const handleClose = () => {

    if (props.onClose)
      props.onClose();
  }

  const handleCreateRoom = async () => {

    try {
      let info;
      if (roomName && roomName.length > 0) {

        const groupNames = selectedUserGroups.map(gr => gr.name);

        info = {
          name: roomName,
          usergroups: groupNames,
          emptyTimeout: emptyTimeout * 60,
          maxParticipants: maxParticipants
        }
      }

      setIsCreating(true);
      const ret = await postToServer('conference/rooms', info, server);

      if (ret.status === 200) {
        dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Conference room  ${info.name} created.` } });
        setUpdated(Date.now());
        setRoomName('');
      }
      else
        dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Error creating room ${info.name}: ${ret.response.data}`, severity: 'error' } });
    } catch (error) {
      dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Error creating room : ${error.message}. ${error.response.data}`, severity: 'error' } });
    }
    finally {
      setIsCreating(false);
    }
  };


  const handleConfirm = async () => {

    setIsConfirmDlgOpen(false);

    const info = actionInfo.data;
    if (actionInfo.action === 'delete') {
      try {
        setIsCreating(true);
        const ret = await callServer('delete', `conference/rooms/${info.name}`, undefined, server);

        if (ret.status === 200) {
          dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Conference room  ${info.name} deleted.` } });
          setUpdated(Date.now());
        }
        else
          dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Error deleting room ${info.name}: ${ret.response.data}.`, severity: 'error' } });

      } catch (error) {
        dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Error deleting room ${info.name}: ${error.message}. ${error.response.data}`, severity: 'error' } });
      }
      finally {
        setIsCreating(false);
      }
    }
  }

  const handleRoomDelete = (info) => {
    setConfirmDlgMessage('Are you sure you want to delete a room?');
    setActionInfo({
      ...actionInfo,
      action: 'delete',
      data: info
    });
    setIsConfirmDlgOpen(true);
  };

  const handleRoomClick = async info => {

    let token;
    setIsCreating(true);

    try {

      const data = {
        "name": info.name,
        "participantName": server.clientId
      }

      const ret = await postToServer('conference/token', data, server);
      if (ret.status === 200) {
        token = ret.data;
      } else {
        dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Error joining room ${info.name}: ${ret.response.data}.`, severity: 'error' } });
      }

    } catch (error) {
      dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Error joining room: ${error.message}. ${error.response.data}`, severity: 'error' } });
    }

    setIsCreating(false);

    props.onClose(info, token);
  };

  const handleUserGroupsChange = ugs => {
    setSelectedUserGroup(ugs);
  };


  const handleEmptyTimeoutChange = event => {
    setEmptyTimeout(event.target.value);
  }

  const handleMaxParticipantsChange = event => {
    setMaxParticipants(event.target.value);
  }


  return (
    <div>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="create-conf-dialog-slide-title"
      >
        <DialogTitle id="create-conf-dialog-slide-title">🗣️ Conference rooms</DialogTitle>
        <DialogContent>
          {userRoleAdmin &&
            <Grid container spacing={1} alignItems="center" >
              <Grid item xs={4}>
                <Tooltip title='Select room name'>
                  <FormControl className={classes.formControl}>
                    <TextField label="Room name" size="small" value={roomName} error={createRoomButtonError !== undefined} helperText={createRoomButtonError} onChange={handleRoomNameChange} />
                  </FormControl>
                </Tooltip>
              </Grid>
              <Grid item xs={5}>
                <Tooltip title='Select user groups'>
                  <FormControl className={classes.formControl}>
                    <InputLabel>User group</InputLabel>
                    <SelectUsergroups canDeleteDemo selectedUserGroups={selectedUserGroups} onUserGroupsChange={handleUserGroupsChange} />
                  </FormControl>
                </Tooltip>
              </Grid>
              <Grid item xs={1}>
                <Tooltip title='Advanced settings'>
                  <ToggleButton
                    className={classes.buttonSettings}
                    value="check"
                    size="small"
                    selected={showAdvancedSettings}
                    onChange={() => {
                      setShowAdvancedSettings(!showAdvancedSettings);
                    }}
                  >
                    <SettingsIcon />
                  </ToggleButton>
                </Tooltip>
              </Grid>
              <Grid item xs={1}>
                <Button variant="outlined" className={classes.buttonCreate} color="primary" size="small" disabled={!createRoomButtonEnabled} onClick={handleCreateRoom}>
                  Create
                </Button>
              </Grid>
            </Grid>
          }
          {showAdvancedSettings &&
            <Grid container spacing={1} alignItems="center" >
              <Grid item xs={4}>
                <Tooltip title='Idle timeout (in minutes)'>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="emptyTimeout-number"
                      label="Idle timeout"
                      type="number"
                      value={emptyTimeout}
                      onChange={handleEmptyTimeoutChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: 5
                      }}
                    />
                  </FormControl>
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <Tooltip title='Max participants in the group'>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="maxParticipants-number"
                      label="Max participants"
                      type="number"
                      value={maxParticipants}
                      onChange={handleMaxParticipantsChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: 1
                      }}
                    />
                  </FormControl>
                </Tooltip>
              </Grid>
            </Grid>
          }
          <Divider />
          <div className={classes.progress}>
            {
              isCreating && <LinearProgress color="secondary" />
            }
          </div>
          <div className={classes.progress}>
            {
              isLoading && <LinearProgress />
            }
          </div>
          <br />
          {rooms && rooms.length > 0 ? <Typography variant="overline">Click the room to join</Typography> : <Typography variant="overline">No rooms found</Typography>}
          <br />
          <div className={classes.root}>
            {
              rooms && rooms.map((room, index) => (
                <Chip variant="outlined"
                  key={room.name}
                  size="small"
                  label={room.name}
                  color={getRoomColor(room)}
                  clickable
                  onClick={() => handleRoomClick(room)}
                  onDelete={userRoleAdmin ? () => handleRoomDelete(room) : undefined}
                  icon={<PersonIcon />}
                />
              ))
            }
          </div>
          { hasError != undefined && hasError !== null && <Alert variant="outlined" severity="error">
                Error accessing videoconferencing api
              </Alert>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isConfirmDlgOpen}
        TransitionComponent={Transition}
      >
        <DialogTitle id="confirm-dialog-slide-title">
        </DialogTitle>
        <DialogContent>
          <p>{confirmDlgMessage}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} color="primary">
            Yes
          </Button>
          <Button onClick={() => { setIsConfirmDlgOpen(false) }} color="default">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}