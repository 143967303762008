import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import PlatformTable from './Components/PlatformTable';
import CreateMissionDlg from './CreateMissionDlg';
import { appContext, serverContext, putToServer } from './App';
import Paper from '@material-ui/core/Paper';


export default function Platforms() {

    const { dispatchApp } = useContext(appContext);
    
    const { server, dispatchServer } = useContext(serverContext);
    const [createMissionCtrl, setCreateMissionCtrl] = useState({ open: false, editMode: false });
    const [selectedPlatform, setSelectedPlatform] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const prepare = () => {
            if (server.userRole) {
                const userRoleAdmin = server.userRole === 'superAdmin' || server.userRole === 'admin' || server.userRole === 'groupAdmin';
                if (!userRoleAdmin) {
                    const message = `Please note, you don't have Admin rights to control platforms.`;
                    dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: message, severity: 'warning' } });
                }
            }

            dispatchServer({ type: 'LIVE_STREAM_EVENTS', payload: 'SUBSCRIBE' });
        };
        prepare();

        return () => {
            dispatchServer({ type: 'LIVE_STREAM_EVENTS', payload: 'UNSUBSCRIBE' });
        };
    }, [server.userRole, dispatchApp, dispatchServer]);

    const handleCreateMission = async newMission => {
        try {
            setCreateMissionCtrl({ open: false })
            setLoading(true);
            newMission.platform.name = selectedPlatform.platformName;
            newMission.platform.type = selectedPlatform.platformType;
            newMission.livemode = true;

            const usergroups = newMission.usergroups.length > 0 ? newMission.usergroups : [];
            const sensors = newMission.sensors.length > 0 ? newMission.sensors : [];

            const mi = await putToServer(`platforms/control?command=start&record=true` +
                `&platformName=${selectedPlatform.platformName}` +
                `&platformType=${newMission.platform.type}` +
                `&missionName=${newMission.name}` +
                `&description=${newMission.description}` +
                `&usergroups=${usergroups}` +
                `&sensors=${sensors}` +
                `&autostart=false`, {}, server);

            if(mi.data.Warning)               
                dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Mission ${newMission.name} added. ${mi.data.Warning}`, severity: 'warning' } });
            else
                dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Mission ${newMission.name} added.` } });
        } catch (error) {
            dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error adding mission: ${error.response ? error.response.data : error.message}` } });
        }

        setLoading(false);
    }

    const handleStopPlatform = async platform => {
        try {
            setLoading(true);

            // TODO:
            //const checkedSensors = platform.sensors.filter(s => s.checked);
            //const sensors = checkedSensors.map(s => s.sensorName);
            const sensors = platform.sensors.map(s => s.sensorName);

            await putToServer(`platforms/control?command=stop` +
                `&platformName=${platform.platformName}` +
                `&sensors=${sensors}` +
                `&stopMission=true`,
                {}, server);

            dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Platform ${platform.platformName} stopped.` } });
        } catch (error) {
            dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error stopping platform: ${error.response ? error.response.data : error.message}` } });
        }

        setLoading(false);
    }

    const handleStartSensor = async sensor => {
        try {
            setLoading(true);
        
            const sensors = [sensor.sensorName];

            await putToServer(`platforms/control?command=start` +
            `&platformName=${sensor.platformName}` +
            `&platformType=${sensor.platformType}` +
            `&sensors=${sensors}`,            
            {}, server);

            // await putToServer(`platforms/control/sensor?command=start` +
            //     `&platformName=${sensor.platformName}` +
            //     `&sensorName=${sensor.sensorName}`,
            //     {}, server);

            dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Sensor ${sensor.name} started.` } });
        } catch (error) {
            dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error starting sensor: ${error.response ? error.response.data : error.message}` } });
        }

        setLoading(false);
    }

    const handleStopSensor = async sensor => {
        try {
            setLoading(true);

            const sensors = [sensor.sensorName];

            await putToServer(`platforms/control?command=stop` +
            `&platformName=${sensor.platformName}` +
            `&sensors=${sensors}`,
            {}, server);

            dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Sensor ${sensor.platformName}/${sensor.sensorName} stopped.` } });
        } catch (error) {
            dispatchApp({ type: 'SHOW_ALERT', payload: { title: 'Error', message: `Error stopping sensor: ${error.response ? error.response.data : error.message}` } });
        }

        setLoading(false);
    }


    /**
     * Called when command button (record) is pressed
     * @param  {} cmd
     * @param  {} platformOrSensor
     * 
     * Record command can be issued for entire platform, one sensor or for multiple selected sensors.
     * Control scenarios:
     * 1. There is no running live mission and user pressed record for one particular sensor.
     *      In this case we should create a new mission and start the sensor.  
     * 
     */
    const handleCommand = (cmd, platformOrSensor) => {
        switch (cmd) {
            case 'record':
                if (platformOrSensor.type === 'platform') {     // For platform
                    const platform = platformOrSensor;
                    setSelectedPlatform(platform);
                    if (!platform.live)
                        setCreateMissionCtrl({ open: true, editMode: false })
                    else
                        handleStopPlatform(platform);   
                } else {                                        // For sensor

                    const sensor = platformOrSensor;

                    if (!sensor.platformLive) {
                        const platform = {
                            platformName: sensor.platformName,
                            platformType: sensor.platformType,
                            sensors: [{
                                checked: true,
                                sensorName: sensor.sensorName
                            }]
                        }
                        setSelectedPlatform(platform);
                        setCreateMissionCtrl({ open: true, editMode: false })
                    }
                    else {
                        if (!sensor.live)
                            handleStartSensor(sensor);
                        else
                            handleStopSensor(sensor);
                    }
                }
                break;

            default:
                break;
        }
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper elevation={1}>
                        <PlatformTable admin onCommand={handleCommand} />
                    </Paper>
                </Grid>
            </Grid>
            <CreateMissionDlg open={createMissionCtrl.open} editMode={createMissionCtrl.editMode} platform={selectedPlatform} liveMissionMode={true} onClose={() => setCreateMissionCtrl({ open: false, editMode: false })} onCreateMission={handleCreateMission} />
            {loading && <LinearProgress />}
        </div>
    );
}