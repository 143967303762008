import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import DocumentsTable from './Components/DocumentsTable';
import { appContext, serverContext } from './App';

const useStyles = makeStyles(theme => ({
    toolboxHeader: {
        flexGrow: 1,
    },
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
}));


/**
 * Documents
 * 
 * @param  {} props
 */
export default function Documents(props) {
    const classes = useStyles();
    const { server, dispatchServer } = useContext(serverContext);
    const { dispatchApp } = useContext(appContext);
    const [queryParams, setQueryParams] = useState({});


    useEffect(() => {

        // let qp = {};
        // if (queryString && props.search.length > 0)
        //     qp = queryString.parse(props.search);

        const filterStr = sessionStorage.getItem('docQueryFilter');
        if (filterStr) {
            const filter = JSON.parse(filterStr);
            setQueryParams(filter);
        }

    }, [props.search])


    const handleSelectedFilter = (selection, state, params) => {

        switch (selection) {

            // case 'geo':
            //     setSelectMapControl(state ? MapControlsMode.FilterMissions : MapControlsMode.None);
            //     if (params && params.hasOwnProperty('aoiMode')) {
            //         setAoiControlsMode(params.aoiMode);
            //         if (state)
            //             dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: `Please select on a map the "Filter Documents" mode with the required (${params.aoiMode ? params.aoiMode.toUpperCase() : '-'}) AOI mode`, severity: 'info' } });
            //     }

            //     break;

            default:
                break;
        }
    }

    const handleApplyQueryFilter = async filter => {
        try {
            if (filter)
                sessionStorage.setItem('docQueryFilter', JSON.stringify(filter));
            else
                sessionStorage.removeItem("docQueryFilter");

        } catch (error) {
        }

        setQueryParams(filter);
    }

    const handleShareUrl = url => {
        try {
            window.open(`mailto:?subject=StServer%20video%20link&body=${url}`);
        } catch (error) {

        }
    }

    const handleError = err => {
        dispatchApp({ type: 'SHOW_SNACKBAR_MESSAGE', payload: { message: err, severity: 'error' } });
    }

    const handleUpdateDocuments = () => {
        try {
            dispatchServer({ type: 'SET_UPDATE_DOCUMENTS', payload: {} });
        } catch (error) {

        }
    }

    return (
        <div className={classes.root} style={{ 'paddingLeft': '0px' }}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                    <DocumentsTable 
                        server={server}
                        documentsFilter={queryParams}
                        documentsUpdateTime={server.documentsUpdate}
                        onSelectedFilter={handleSelectedFilter}
                        onApplyQueryFilter={handleApplyQueryFilter}
                        onUpdateDocuments={handleUpdateDocuments}
                        onShare={handleShareUrl}
                        onError={handleError} />
                </Grid>
            </Grid>
        </div>
    );
}