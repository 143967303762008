import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Map } from 'react-leaflet';
import FullscreenControl from 'react-leaflet-fullscreen';
import PlatformsList from './Platform';
import MapLayersControl from './MapLayerControl';
import PropTypes from 'prop-types';
import sizeMe from 'react-sizeme';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';
import QueryIcon from '@material-ui/icons/Search';
import AoiIcon from '@material-ui/icons/GpsFixed';
//import GeojsonLayer from './GeoJsonLayer';

import 'react-leaflet-fullscreen/dist/styles.css'
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

import L from 'leaflet';
import 'leaflet.polylinemeasure/Leaflet.PolylineMeasure.css';
import 'leaflet-easybutton/src/easy-button.css';
import 'leaflet-easybutton/src/easy-button';
import 'leaflet.polylinemeasure/Leaflet.PolylineMeasure';
import 'leaflet-spin';

import 'leaflet-draw';
import 'leaflet.heat';


function MapCenterIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M5 15H3v4c0 1.1.9 2 2 2h4v-2H5v-4zM5 5h4V3H5c-1.1 0-2 .9-2 2v4h2V5zm14-2h-4v2h4v4h2V5c0-1.1-.9-2-2-2zm0 16h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
        </SvgIcon>
    );
}


const ShowHeatmapIcon = `<svg > 
<path id="path2" fill="none" d="M0 0h24v24H0V0z" /> <g transform="translate(-3)" id="g10"> <circle id="circle4" r="3" cy="14" cx="7" /> <circle id="circle6" r="3" cy="6" cx="11" />
<circle id="circle8" r="3" cy="17.6" cx="16.6" /> </g>
</svg>`;

const HideHeatmapIcon = `<svg > 
<path id="path2" fill="none" d="M0 0h24v24H0V0z" />
<g transform="translate(-3)" id="g10"> 
<circle id="circle4" r="3" cy="14" cx="7" style="fill:#ff0000;stroke:#ff0000;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" />
<circle id="circle6" r="3" cy="6" cx="11" style="fill:#ff0000;stroke:#ff0000;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" /> 
<circle id="circle8" r="3" cy="17.6" cx="16.6" style="fill:#ff0000;stroke:#ff0000;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" /> </g> &quot; 
<path id="path112" d="M 1.3898081,2.7796161 18.067505,19.854401" style="fill:#ff0000;stroke:#000000;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" />
<path id="path118" d="M 0.59563203,18.663137 18.067505,3.5737922" style="fill:none;stroke:#000000;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" />
</svg>`;

const ShowAreaIcon = `<svg >
<path style="stroke-width:0.710465" id="path100" d="M 6.7571145,9.5683197 V 1 L 12.661152,6.6426208 V 19.808736 H 3 V 6.6426208 Z M 7.8305758,4.1683316 V 12.462984 L 4.0734613,9.5372852 V 17.927862 H 11.58769 V 7.7589193 L 7.8305758,4.169272 Z" />
</svg>`;

const HideAreaIcon = `<svg >
<path id="path98" d="M0 0h24v24H0z" fill="none" /> 
<path  id="path100" d="M 7.5292301,9.5683197 V 1 L 14.646592,6.6426208 V 19.808736 H 3 V 6.6426208 Z M 8.8232958,4.1683316 V 12.462984 L 4.2940657,9.5372852 V 17.927862 H 13.352526 V 7.7589193 L 8.8232958,4.169272 Z" style="fill:#ff0000;stroke:#ff0000;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" /> 
<path id="path112" d="M 1.3898081,2.7796161 18.067505,19.854401" style="fill:#ff0000;stroke:#000000;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" />
<path id="path118" d="M 0.59563203,18.663137 18.067505,3.5737922" style="fill:none;stroke:#000000;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" />
</svg>`;

const ShowPathIcon = `<svg >
<g transform="translate(-3)"> 
<path d="M4.59 6.89c.7-.71 1.4-1.35 1.71-1.22.5.2 0 1.03-.3 1.52-.25.42-2.86 3.89-2.86 6.31 0 1.28.48 2.34 1.34 2.98.75.56 1.74.73 2.64.46 1.07-.31 1.95-1.4 3.06-2.77 1.21-1.49 2.83-3.44 4.08-3.44 1.63 0 1.65 1.01 1.76 1.79-3.78.64-5.38 3.67-5.38 5.37 0 1.7 1.44 3.09 3.21 3.09 1.63 0 4.29-1.33 4.69-6.1H21v-2.5h-2.47c-.15-1.65-1.09-4.2-4.03-4.2-2.25 0-4.18 1.91-4.94 2.84-.58.73-2.06 2.48-2.29 2.72-.25.3-.68.84-1.11.84-.45 0-.72-.83-.36-1.92.35-1.09 1.4-2.86 1.85-3.52.78-1.14 1.3-1.92 1.3-3.28C8.95 3.69 7.31 3 6.44 3 5.12 3 3.97 4 3.72 4.25c-.36.36-.66.66-.88.93l1.75 1.71zm9.29 11.66c-.31 0-.74-.26-.74-.72 0-.6.73-2.2 2.87-2.76-.3 2.69-1.43 3.48-2.13 3.48z"/>
</svg>`;

const HidePathIcon = `<svg >
<g transform="translate(-3)"> 
<path transform="matrix(0.93078281,0,0,0.94812803,0.05597788,0.05108733)"
d="M 4.59,6.89 C 5.29,6.18 5.99,5.54 6.3,5.67 6.8,5.87 6.3,6.7 6,7.19 5.75,7.61 3.14,11.08 3.14,13.5 c 0,1.28 0.48,2.34 1.34,2.98 0.75,0.56 1.74,0.73 2.64,0.46 1.07,-0.31 1.95,-1.4 3.06,-2.77 1.21,-1.49 2.83,-3.44 4.08,-3.44 1.63,0 1.65,1.01 1.76,1.79 -3.78,0.64 -5.38,3.67 -5.38,5.37 0,1.7 1.44,3.09 3.21,3.09 1.63,0 4.29,-1.33 4.69,-6.1 H 21 v -2.5 h -2.47 c -0.15,-1.65 -1.09,-4.2 -4.03,-4.2 -2.25,0 -4.18,1.91 -4.94,2.84 C 8.98,11.75 7.5,13.5 7.27,13.74 7.02,14.04 6.59,14.58 6.16,14.58 5.71,14.58 5.44,13.75 5.8,12.66 6.15,11.57 7.2,9.8 7.65,9.14 8.43,8 8.95,7.22 8.95,5.86 8.95,3.69 7.31,3 6.44,3 5.12,3 3.97,4 3.72,4.25 3.36,4.61 3.06,4.91 2.84,5.18 Z m 9.29,11.66 c -0.31,0 -0.74,-0.26 -0.74,-0.72 0,-0.6 0.73,-2.2 2.87,-2.76 -0.3,2.69 -1.43,3.48 -2.13,3.48 z"
style="fill:#ff0000;stroke:#ff0000;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"/>
<path id="path112" d="M 1.3898081,2.7796161 18.067505,19.854401" style="fill:#ff0000;stroke:#000000;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" />
<path id="path118" d="M 0.59563203,18.663137 18.067505,3.5737922" style="fill:none;stroke:#000000;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" />
</svg>`;



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        background: "#E0E6F8",
        '& > svg': {
            marginLeft: theme.spacing(1),
        },
    },
    formControl: {
        marginRight: theme.spacing(1),
    },
    formControlSelect: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    formIcon: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1)
    }
}));


// Workaround to load the images from local css
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


export const MapCenterMode = {
    Manual: 0,
    Platform: 1,
    Framecenter: 2,
    Target: 3,
    BoundingBox: 4
}

export const MapControlsMode = {
    None: 0,
    QueryMission: 1,
    FilterMissions: 2,
    DrawAnnotations: 3
}

// Area of interest to look for
export const AoiMode = {
    SensorArea: 'sensorArea',
    FrameCenter: 'frameCenter',
    PlatformPath: 'platformPath'
}


const ToolbarCtrl = props => {

    const handleCenterModeChange = event => {
        if (props.onCenterModeChange)
            props.onCenterModeChange(event.target.value);
    };

    const handleMapControlsChange = event => {
        if (props.onMapControlsModeChange)
            props.onMapControlsModeChange(event.target.value);
    };

    const handleAoiControlsChange = event => {
        if (props.onAoiControlsModeChange)
            props.onAoiControlsModeChange(event.target.value);
    };


    const classes = useStyles();

    return (
        <Grid container direction="row" className={classes.root} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
            <Grid item >
                <div title="Center map">
                    <MapCenterIcon className={classes.formIcon} fontSize="small" />
                </div>
            </Grid>
            <Grid item >
                <Select className={classes.formControlSelect}
                    value={props.mapCenterMode}
                    onChange={handleCenterModeChange}
                >
                    <MenuItem value={MapCenterMode.Platform}>Platform</MenuItem>
                    <MenuItem value={MapCenterMode.Framecenter}>Frame</MenuItem>
                    <MenuItem value={MapCenterMode.Target}>Target</MenuItem>
                    <Divider />
                    <MenuItem value={MapCenterMode.Manual}>Manual</MenuItem>
                </Select>
            </Grid>
            <Grid item >
                <div title="AOI">
                    <AoiIcon className={classes.formIcon} fontSize="small" />
                </div>
            </Grid>
            <Grid item >
                <Select className={classes.formControlSelect}
                    value={props.aoiControlsMode}
                    onChange={handleAoiControlsChange}
                >
                    <MenuItem value={AoiMode.SensorArea}>Sensor Area</MenuItem>
                    <MenuItem value={AoiMode.FrameCenter}>Frame Center</MenuItem>
                    <MenuItem value={AoiMode.PlatformPath}>Platform Path</MenuItem>
                </Select>
            </Grid>
            <Grid item >
                <div title="Query">
                    <QueryIcon className={classes.formIcon} fontSize="small" />
                </div>
            </Grid>
            <Grid item >
                <Select className={classes.formControlSelect}
                    value={props.mapControlsMode}
                    onChange={handleMapControlsChange}
                >
                    <MenuItem value={MapControlsMode.None}>None</MenuItem>
                    <MenuItem value={MapControlsMode.QueryMission}>Query Mission</MenuItem>
                    <MenuItem value={MapControlsMode.FilterMissions}>Filter Missions</MenuItem>
                    {/* <MenuItem value={MapControlsMode.DrawAnnotations}>Draw Annotations</MenuItem> */}
                </Select>
            </Grid>
        </Grid>
    );
}

/*
* UavMap shows platforms on the map
*/
class UavMap extends Component {

    static propTypes = {
        platforms: PropTypes.array,
        selectedChannel: PropTypes.number,
        showVmti: PropTypes.bool,
        showTrail: PropTypes.bool,
        trailDuration: PropTypes.number,
        mapCenterMode: PropTypes.oneOf([MapCenterMode.Manual, MapCenterMode.Platform, MapCenterMode.Framecenter, MapCenterMode.Target, MapCenterMode.BoundingBox]),
        mapControlsMode: PropTypes.oneOf([MapControlsMode.None, MapControlsMode.QueryMission, MapControlsMode.FilterMissions, MapControlsMode.DrawAnnotations]),
        aoiControlsMode: PropTypes.oneOf([AoiMode.SensorArea, AoiMode.FrameCenter, AoiMode.PlatformPath]),
        lastMapCenter: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object
        ]),
        lastMapZoom: PropTypes.number,
        dragging: PropTypes.bool,
        onClose: PropTypes.func
    }

    constructor(props) {
        super(props);

        this.state = {
            zoom: props.lastMapZoom,
            mapCenterMode: props.mapCenterMode,
            curMapControlsMode: props.mapControlsMode,
            aoiControlsMode: props.aoiControlsMode,
            appBarOpen: false,
        }

        this.handleMapOptionsClick = this.handleMapOptionsClick.bind(this);
        this.handleCenterModeChange = this.handleCenterModeChange.bind(this);
        this.handleMapControlsModeChange = this.handleMapControlsModeChange.bind(this);
        this.handleShowHeatmapClick = this.handleShowHeatmapClick.bind(this);
        this.handleHideHeatmapClick = this.handleHideHeatmapClick.bind(this);
        this.handleShowAreaClick = this.handleShowAreaClick.bind(this);
        this.handleHideAreaClick = this.handleHideAreaClick.bind(this);

        this.timer = setInterval(() => this.checkDimensions(), 500);
        this.heatmapLayerGroup = undefined;
        this.heatmapLayersInfo = [];
        this.mapHeatmapPoints = props.mapHeatmapPoints;
    }


    componentDidMount() {

        this.uavMapRef = React.createRef();
        const map = this.refs.map.leafletElement;

        L.easyButton('<span class="star">&equiv;</span>', this.handleMapOptionsClick).addTo(map);
        L.easyButton({
            states: [{
                stateName: 'show-heatmap-on',
                icon: ShowHeatmapIcon,
                title: 'Show heatmap',
                onClick: (btn, map) => {
                    btn.state('show-heatmap-off');
                    this.handleShowHeatmapClick(map, cb => {
                        btn.state('show-heatmap-on');
                    });
                }
            }, {
                stateName: 'show-heatmap-off',
                icon: HideHeatmapIcon,
                title: 'Hide heatmap',
                onClick: (btn, map) => {
                    btn.state('show-heatmap-on');
                    this.handleHideHeatmapClick(map);
                }
            }]
        }).addTo(map);
        L.easyButton({
            states: [{
                stateName: 'show-area-on',
                icon: ShowAreaIcon,
                title: 'Show area',
                onClick: (btn, map) => {
                    btn.state('show-area-off');
                    this.handleShowAreaClick(map, cb => {
                        btn.state('show-area-on');
                    });
                }
            }, {
                stateName: 'show-area-off',
                icon: HideAreaIcon,
                title: 'Hide area',
                onClick: (btn, map) => {
                    btn.state('show-area-on');
                    this.handleHideAreaClick(map);
                }
            }]
        }).addTo(map);
        L.easyButton({
            states: [{
                stateName: 'show-path-on',
                icon: ShowPathIcon,
                title: 'Show path',
                onClick: (btn, map) => {
                    btn.state('show-path-off');
                    this.handleShowPathClick(map, cb => {
                        btn.state('show-path-on');
                    });
                }
            }, {
                stateName: 'show-path-off',
                icon: HidePathIcon,
                title: 'Hide path',
                onClick: (btn, map) => {
                    btn.state('show-path-on');
                    this.handleHidePathClick(map);
                }
            }]
        }).addTo(map);


        //  L.control.polylineMeasure({ position: 'topleft', unit: 'metres', showBearings: true, clearMeasurementsOnStop: true, showClearControl: true, showUnitControl: true }).addTo(map);
        L.control.scale().addTo(map);

        if (this.props.dragging)
            map.dragging.enable();
        else
            map.dragging.disable();

        map.on('overlayadd', this.onOverlayAdd);
        map.on('overlayremove', this.onOverlayRemove);
        map.on('baselayerchange', this.onBaseLayerChange);

        map.on('mouseover', e => {
            this.props.onMouseOver();
            if (!this.state.appBarOpen)
                this.setState({ appBarOpen: true });
        })

        map.on('mouseout ', e => {
            this.props.onMouseLeave();
            this.closeToolbarTimer = setTimeout(() => {
                this.setState({ appBarOpen: false })
            }, 1000);
        })

        // Draw control      
        map.on(L.Draw.Event.CREATED, event => {
            this.doDrawActionCreated(event);
        });
        map.on(L.Draw.Event.EDITED, event => {
            this.doDrawActionEdited(event);
        });
        map.on(L.Draw.Event.DELETED, event => {
            this.doDrawActionDeleted(event);
        });

        // this.areaLayerGroup = new L.LayerGroup().addTo(map);

        this.selectDrawControls(this.props.mapControlsMode);

        this.mapViewUpdateTimer = setInterval(() => {
            if (this.state.mapCenterMode !== MapCenterMode.Manual) {
                const mapCenter = this.getMapCenter();

                if (map && Array.isArray(mapCenter) && mapCenter.length === 2)
                    map.setView(mapCenter, this.state.zoom, { animation: true });
            }

            if (this.state.curMapControlsMode !== this.props.mapControlsMode)
                this.selectDrawControls(this.props.mapControlsMode);

        }, 2000);

    }


    selectDrawControls(mapControlsMode) {
        const map = this.refs.map.leafletElement;
        let controls;

        if (this.drawnItems)
            map.removeLayer(this.drawnItems);

        switch (mapControlsMode) {

            case MapControlsMode.None:
                if (this.drawControl) {
                    map.removeControl(this.drawControl);
                }

                break;

            case MapControlsMode.QueryMission:
                if (this.drawnItems)
                    map.removeLayer(this.drawnItems);
                controls = {
                    rectangle: true,
                    polygon: true,
                    polyline: false,
                    circle: false,
                    marker: false,
                    circlemarker: false
                }
                break;

            case MapControlsMode.FilterMissions:
                if (this.drawnItems)
                    map.removeLayer(this.drawnItems);
                controls = {
                    rectangle: true,
                    polygon: true,
                    polyline: false,
                    circle: false,
                    marker: true,
                    circlemarker: false
                }
                break;

            case MapControlsMode.DrawAnnotations:
                controls = {
                    rectangle: true,
                    polygon: {
                        allowIntersection: false,
                        showArea: true
                    },
                    polyline: true,
                    circle: true,
                    marker: false,
                    circlemarker: false
                }
                break;

            default:
                break;
        }

        if (controls) {
            if (this.drawControl)
                map.removeControl(this.drawControl);

            this.drawnItems = new L.FeatureGroup();
            map.addLayer(this.drawnItems);
            this.drawControl = new L.Control.Draw({
                position: 'bottomright',
                draw: controls,
                edit: {
                    featureGroup: this.drawnItems
                }
            });
            map.addControl(this.drawControl);
        }

        this.setState({ curMapControlsMode: mapControlsMode });
    }

    async doDrawActionCreated(event) {

        const map = this.refs.map.leafletElement;

        if (this.drawnItems)
            this.drawnItems.clearLayers();

        const layer = event.layer;
        this.drawnItems.addLayer(layer);

        map.spin(true);

        if (this.props.onDrawAction)
            await this.props.onDrawAction(this.props.mapControlsMode, event.type, this.drawnItems.toGeoJSON());

        map.spin(false);
    }

    async doDrawActionEdited(event) {

        const map = this.refs.map.leafletElement;
        map.spin(true);

        if (this.props.onDrawAction)
            await this.props.onDrawAction(this.props.mapControlsMode, event.type);

        map.spin(false);
    }

    async doDrawActionDeleted(event) {

        const map = this.refs.map.leafletElement;
        map.spin(true);

        if (this.props.onDrawAction)
            await this.props.onDrawAction(this.props.mapControlsMode, event.type);

        map.spin(false);
    }

    componentWillUnmount = () => {

        try {

            if (this.timer) {
                clearInterval(this.timer);
            }

            if (this.mapViewUpdateTimer) {
                clearInterval(this.mapViewUpdateTimer);
            }

            if (this.props.onClose) {
                const map = this.refs.map.leafletElement;
                if (map) {
                    const mapCenter = map.getCenter();
                    const mapProps = {
                        center: [mapCenter.lat, mapCenter.lng],
                        zoom: this.state.zoom,
                        mapCenterMode: this.state.mapCenterMode,
                        mapControlsMode: this.props.mapControlsMode,
                        aoiControlsMode: this.state.aoiControlsMode
                    }
                    this.props.onClose(mapProps);
                }
            }
        } catch (error) {

        }
    }


    onOverlayAdd = e => {
        switch (e.name) {
            case 'Symbols':
                localStorage.setItem('Symbols', 'true');
                break;
            case 'Tactical Symbols':
                localStorage.setItem('Tactical_Symbols', 'true');
                break;
            case 'ADS-B':
                localStorage.setItem('ADS-B', 'true');
                break;
            default:
                break;
        }
    }

    onOverlayRemove = e => {
        switch (e.name) {
            case 'Symbols':
                localStorage.setItem('Symbols', 'false');
                break;
            case 'Tactical Symbols':
                localStorage.setItem('Tactical_Symbols', 'false');
                break;
            case 'ADS-B':
                localStorage.setItem('ADS-B', 'false');
                break;
            default:
                break;
        }
    }

    onBaseLayerChange = e => {
        localStorage.setItem("baseLayerMap", e.name);
    }

    // workaround for map redraw
    checkDimensions = () => {

        const parent = ReactDOM.findDOMNode(this).parentNode;

        const { width, height } = this.props.size;

        if (width !== parent.clientWidth - 4 || height !== parent.clientHeight - 4) {
            const map = this.refs.map.leafletElement;
            if (map)
                map.invalidateSize(false);
        }
    }


    handleViewportChanged = (viewport) => {
        this.state.zoom = viewport.zoom;
    }


    handleMapOptionsClick() {
        this.setState({ appBarOpen: !this.state.appBarOpen });
    }

    handleMapShowAreaClick() {
        this.setState({ appBarOpen: !this.state.appBarOpen });
    }

    handleCenterModeChange = value => {
        this.setState({ mapCenterMode: value });

        const map = this.refs.map.leafletElement;
        const mapCenter = this.getMapCenter();

        if (map && Array.isArray(mapCenter) && mapCenter.length === 2)
            map.setView(mapCenter, this.state.zoom, { animation: true });
    }

    handleMapControlsModeChange = value => {
        this.selectDrawControls(value);
        if (this.props.onMapControlsModeChange)
            this.props.onMapControlsModeChange(value);
    }

    handleAoiControlsModeChange = value => {
        this.setState({ aoiControlsMode: value });

        if (this.props.onAoiControlsModeChange)
            this.props.onAoiControlsModeChange(value);
    }

    handleShowHeatmapClick = async (map, cb) => {
        map.spin(true);
        try {
            const hPoints = await this.props.onShowHeatmapClick();
            this.heatLayer = L.heatLayer(hPoints).addTo(map);
        } catch (error) {
            if (cb)
                cb();
        }
        map.spin(false);
    }

    handleHideHeatmapClick = map => {
        if (this.heatLayer)
            map.removeLayer(this.heatLayer)
    }


    handleShowAreaClick = async (map, cb) => {
        map.spin(true);
        try {
            const area = await this.props.onShowAreaClick();
            this.areaLayer = L.geoJSON(area).addTo(map);
        } catch (error) {
            if (cb)
                cb();
        }
        map.spin(false);
    }

    handleHideAreaClick = map => {
        if (this.areaLayer)
            map.removeLayer(this.areaLayer)
    }


    handleShowPathClick = async (map, cb) => {
        map.spin(true);
        try {
            const path = await this.props.onShowPathClick();
            this.pathLayer = L.geoJSON(path).addTo(map);
        } catch (error) {
            if (cb)
                cb();
        }
        map.spin(false);
    }

    handleHidePathClick = map => {
        if (this.pathLayer)
            map.removeLayer(this.pathLayer)
    }


    /**
     * Get current map center
     */
    getMapCenter() {
        let center;

        if (!this.props.platforms || this.props.platforms.length === 0)
            return this.props.lastMapCenter;

        const activePlatform = this.props.selectedChannel || 0;

        if (activePlatform === -1 || !this.props.platforms[activePlatform])
            return this.props.lastMapCenter;

        switch (this.state.mapCenterMode) {

            case MapCenterMode.Platform:
                if (this.props.platforms[activePlatform].klvs.hasOwnProperty('13') && this.props.platforms[activePlatform].klvs.hasOwnProperty('14')) {
                    center = [this.props.platforms[activePlatform].klvs['13'], this.props.platforms[activePlatform].klvs['14']];
                }
                break;

            case MapCenterMode.Framecenter:
                if (this.props.platforms[activePlatform].klvs.hasOwnProperty('23') && this.props.platforms[activePlatform].klvs.hasOwnProperty('24')) {
                    center = [this.props.platforms[activePlatform].klvs['23'], this.props.platforms[activePlatform].klvs['24']];
                }
                break;

            case MapCenterMode.Target:
                if (this.props.platforms[activePlatform].klvs.hasOwnProperty('40') && this.props.platforms[activePlatform].klvs.hasOwnProperty('41'))
                    center = [this.props.platforms[activePlatform].klvs['40'], this.props.platforms[activePlatform].klvs['41']];
                break;

            case MapCenterMode.BoundingBox:
                break;

            case MapCenterMode.Manual:
                center = this.props.lastMapCenter;
                break;

            default:
                center = this.props.lastMapCenter;
                break;
        }
        return center;
    }

    handleToolbarMouseEnter = () => {
        if (this.closeToolbarTimer)
            clearTimeout(this.closeToolbarTimer);
        this.setState({ appBarOpen: true });
    }

    handleToolbarMouseLeave = () => {
        this.setState({ appBarOpen: false });
    }


    render() {

        const { width, height } = this.props.size;

        return (
            <div className="leaflet-container" ref={this.uavMapRef} style={{ width: this.props.width, height: this.props.height }}>

                {this.state.appBarOpen &&
                    <ToolbarCtrl
                        mapCenterMode={this.state.mapCenterMode}
                        mapControlsMode={this.props.mapControlsMode}
                        aoiControlsMode={this.state.aoiControlsMode}
                        onCenterModeChange={this.handleCenterModeChange}
                        onMapControlsModeChange={this.handleMapControlsModeChange}
                        onAoiControlsModeChange={this.handleAoiControlsModeChange}
                        onMouseEnter={this.handleToolbarMouseEnter}
                        onMouseLeave={this.handleToolbarMouseLeave} />
                }
                <Map ref="map" width={width} height={height}
                    onViewportChanged={this.handleViewportChanged} >
                    <MapLayersControl customMap={this.props.customMap} />
                    <FullscreenControl position="topleft" />
                    <PlatformsList
                        platforms={this.props.platforms}
                        activePlatform={this.props.selectedChannel}
                        showVmti={this.props.showVmti}
                        showTrail={this.props.showTrail} trailDuration={this.props.trailDuration} />
                    {/* <GeojsonLayer /> */}
                </Map>

            </div>
        );
    }
}

export default sizeMe({ monitorHeight: true, refreshRate: 200 })(UavMap);
